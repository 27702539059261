import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  Stack,
} from "@mui/material";
import TableSkeleton from "../TableSkeleton/TableSkeleton";

const CustomTable = ({ data, columns, isLoading }) => {
  console.log(data, "tellmeyournoumber");
  return (
    <>
      <TableContainer component={Paper } style={{overflow:"auto"}}>
        <Table aria-label="simple table" sx={{ backgroundColor: "#eaf6fa" }}>
          <TableHead sx={{ backgroundColor: "#fff" }}>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell
                  sx={{
                    color: "#032c6b",
                    fontSize: "18px",
                    fontWeight: "500",
                    minWidth:column?.minWidth,
                  }}
                  key={index}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* <Stack> */}
          <TableBody>
            {isLoading ? (
              <TableSkeleton columns={columns?.length} />
            ) : (
              data?.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow key={index} sx={{ backgroundColor: "#fff" }}>
                    {columns?.map((column, index) => (
                      <TableCell key={index}>{row[column.id]}</TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))
            )}
          </TableBody>
          {/* </Stack> */}
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTable;

import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { token_api } from "../../Utils/Network";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function StudentProductHistory() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState([]);

  console.log(id, location.state, "pagedatanew");

  useEffect(() => {
    if (id) {
      studentOrderHitory(id);
    }
  }, [id]);

  const studentOrderHitory = (sId) => {
    token_api
      .get(`/counseling/purchases/${sId}/purchased_products/`)
      .then((res) => {
        console.log(res);
        const { data } = res?.data;
        let modifiedArray = data?.map((productData) => {
          const { id, product, expiry_date, purchase_date, payment_status } =
            productData;
          let typeStyle;
          switch (payment_status) {
            case 1:
              typeStyle = <div style={{color:"red"}}>In Progress</div>; // Assuming styles.typeStyleOne exists
              break;
            case 2:
              typeStyle = <div style={{color:"green"}}>Paid</div>;
              break;
            case 3:
              typeStyle = <div style={{color:"red"}}>Unpaid</div>;
              break;
            case 4:
              typeStyle = <div>Failed</div>;
              break;
            case 5:
              typeStyle = <div>Refund</div>;
              break;
            case 6:
              typeStyle = <div>Non Refundable</div>;
              break;
            default:
              typeStyle = <div>N.A</div>;
          }

          return {
            id: id,
            product: product?.product_name,
            purchase_date: purchase_date,
            exp_date: expiry_date,
            session: product?.qty ? "1" : "N.A",
            amount: product?.selling_price,
            payment_status: typeStyle,
          };
        });

        setStudentData(modifiedArray)
      })
      .catch((err) => {
        console.log(err);
      });
  };
console.log(studentData, "iwjeofj9od90w98uh");

  return (
    <>
      <div className={`${styles.conatiner}`}>
        <div className={styles.backBtn}>
          <span
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={() => navigate(`/dashboard/product`)}
          >
            <KeyboardBackspaceIcon /> <h3>Back</h3>
          </span>
        </div>
        <div className={`${styles.filterBox}`}>
          <div className={styles.studentInfoBox}>
            <h3 className={styles.studentname}>
              {location.state?.user_detail?.full_name}
            </h3>
            <h4 className={styles.schoolname}>
              {location.state?.institute == "None"
                ? "School Name"
                : location.state?.institute}
            </h4>
            <p className={styles.class}>{location.state?.grade}</p>
          </div>
        </div>

        <div className={`${styles.tableBox}`}>
          <CustomTable
            columns={columns}
            data={studentData}
            // isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default StudentProductHistory;

const columns = [
  { id: "id", label: "ID" },
  { id: "product", label: "Product Name" },
  { id: "purchase_date", label: "Purchase date" },
  { id: "exp_date", label: "Expiry date" },

  { id: "session", label: "Session" },
  { id: "amount", label: "Amount" },
  { id: "payment_status", label: "Payment stauts" },
];

import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, Input, TextField } from "@mui/material";
import CustomMultiSelectChips from "../CustomMultiSelectChips/CustomMultiSelectChips";
import { open_api, token_api } from "../../Utils/Network";

function AssignCoursesModel({
  open,
  onClose,
  schoolID,
  editState,
  showSections,
  setOpneCoursesModel,
}) {
  const [sectionList, setSectionList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [sectionData, setSectionData] = useState({
    course_id: null,
    section_id: [],
  });
  const [formErrors, setFormErrors] = useState({});

  console.log(schoolID, "showSections");
  const validate = (value) => {
    const { section_id } = sectionData;
    const errors = {
      error: false,
    };
    if (section_id.length == 0) {
      errors.section = "Select section";
      errors.error = true;
    }
    setFormErrors(errors);
    return errors;
  };

  const handleAssignSection = () => {
    // let validateResults = validate(sectionData);
    console.log(coursesList, "needtoconfirmdata");
    // const { error } = validateResults;
    // if (error) return;
    let newMapdata = [];
    let newEditData = [];
    coursesList
      ?.map((course) => {
        console.log(course, "uhefabjahsddedjbfuhshad");
        const course_id = parseInt(course?.id); // Assuming the course ID is directly available in the course object
        const section_value = course?.section_value || [];

        const section_id = section_value
          .filter((section) => section?.id !== undefined) // Filter out sections without valid IDs
          .map((section) => parseInt(section.id)); // Parse section IDs to integers

        if (course_id && section_id.length > 0 && course?.if_edit === false) {
          newMapdata.push({
            course_id,
            section_id,
          });
        } else if (course_id && course?.if_edit === true) {
          newEditData.push({ id: course?.edit_id, course_id, section_id });
        }

        return null; // Return null for courses with empty section_value
      })
      .filter(Boolean); // Filter out null entries to remove courses with empty section_value

    console.log(newMapdata, newEditData, "handleAssignSection"); // post data to post api

    if (newMapdata.length > 0) {
      token_api
        .post(`content/V1/course/mapping/`, { data: newMapdata })
        .then((res) => {
          console.log(res?.data?.data, "newMapdataapires");
          alert("data added")
          setOpneCoursesModel(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (newEditData.length > 0) {
      token_api
        .patch(`content/V1/course/mapping/update_data/`, { data: newEditData })
        .then((response) => {
          console.log(response?.data?.data);
          alert("data updated")
          setOpneCoursesModel(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (editState) {
    //   let sectionIds = sectionData?.section_id?.map((l, i) => {
    //     return l?.id;
    //   });

    //   let data = {
    //     course_id: schoolID,
    //     section_id: sectionIds,
    //   };
    // } else {

    //   let sectionIds = sectionData?.id?.map((l, i) => {
    //     return l?.id;
    //   });

    //   let data = {
    //     course_id: schoolID,
    //     section_id: sectionIds,
    //   };

    // }
  };

  const handleChangeChips = (e, id, index) => {
    // console.log(e, "hsfkusdhfsdjfnsdkjfhsdf");

    const updatedCoursesList = [...coursesList];
    // Update the section_value for the corresponding course
    updatedCoursesList[index].section_value = e;
    // Set the updated coursesList
    setCoursesList(updatedCoursesList);

    // coursesList[index].section_value = e;

    // setCoursesList([...coursesList]);

    // if (id) {
    //   setSectionData((prevData) => {
    //     const newData = { ...prevData };
    //     newData.section_id = [...prevData.section_id];
    //     newData.section_id[index] = {
    //       course_id: id,
    //       section_id: typeof e === "string" ? e.split(",") : e,
    //     };
    //     return newData;
    //     // coursesList[index].section_id = typeof e === "string" ? e.split(",") : e;
    //     // setCoursesList([...coursesList]);
    //   });
    // }
  };
  console.log("YoginCCLIST", sectionData);

  const handleDeleteChips = (id, index) => {
    let storeVal = coursesList[index]?.section_value.filter(
      (chip) => chip.id !== id
    );
    console.log("DelPayload", storeVal);

    // setSectionData({
    //   ...sectionData,
    //   section_id: storeVal,
    // });

    coursesList[index].section_value = storeVal;
    setCoursesList([...coursesList]);
  };
  const getSectionList = () => {
    token_api
      .get(`base/v1/section/?institute_id=${schoolID}`)
      .then((res) => {
        // console.log(res?.data?.data, "getSectionList");
        let modifiedArray = res?.data?.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });
        console.log(modifiedArray, "getSectionList");
        setSectionList(modifiedArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCoursesList = () => {
    token_api
      // .get(`content/v1/course/listing/?institute_id=${schoolID}`)
      .get(`content/v1/course/listing/`)
      .then((res) => {
        console.log(res?.data?.data, "BKJHGJKHKKJHJKHKJ");
        let modifiedArray = res?.data?.data?.map((item, index) => {
          return {
            id: item?.id,
            title: item?.title,
            name: `$section_${index + 1}`,
            section_value: item?.course_mapping[0]?.section,
            if_edit: item?.course_mapping?.length > 0 ? true : false,
            edit_id: item?.course_mapping[0]?.id,
          };
        });
        console.log(modifiedArray, "getSectionList");
        setCoursesList(modifiedArray);
        // for (let i = 0; i < modifiedArray.length; i++) {
        //   if()
        // }
        // modifiedArray.forEach(element => {
        //   if(element.section_value.length > 0){

        //     return
        //   }

        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("YoCourseList", coursesList);

  useEffect(() => {
    getSectionList();
    getCoursesList();
  }, [schoolID]);
  return (
    <Dialog fullWidth={100} maxWidth={"lg"} open={open} onClose={onClose}>
      {/* <DialogTitle>Add Number Of section in each Grade</DialogTitle> */}
      <DialogContent>
        <div className={styles.MainContainer}>
          <h2 className={styles.mainHeading}>Assign Courses to Section</h2>
          <h3 className={styles.infoText}>
            All Student in the section will automatically assinged to the
            courses on mapping courses with section
          </h3>
          <div className={styles.innerContainer}>
            <div className={styles.sepreation}>
              <h4>Courses</h4>
              <h4>Sections</h4>
            </div>
            {/* <div className={styles.sepreation}> */}
            {/* {
              sectionList?.length > 0 ? <>show data</> : <>no Data</>
            } */}
            {coursesList?.map((v, i) => {
              return (
                <div className={styles.sepreation} key={i}>
                  <div className={styles.innerSeprate}>
                    <h5 className={styles.coursesListing}>{v.title}</h5>
                  </div>
                  <div className={styles.innerSeprate}>
                    <CustomMultiSelectChips
                      listArray={sectionList}
                      label={"Section"}
                      name={v?.name}
                      onChange={(e) => handleChangeChips(e, v?.id, i)}
                      handleDelete={(id) => handleDeleteChips(id, i)}
                      value={v?.section_value || []}
                      size={"small"}
                      // error={formErrors?.language}
                    />
                  </div>
                </div>
              );
            })}
            {/* <h5 className={styles.coursesLinsting}></h5> */}

            {/* </div> */}
            <Button variant="contained" onClick={handleAssignSection}>
              {" "}
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AssignCoursesModel;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./addStu.module.css";
import InputField from "../Input/InputFields";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import DragDrop from "../DragDrop/DragDrop";
import { upload } from "@testing-library/user-event/dist/upload";
import axios from "axios";
import { base_url } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useContext } from "react";
import { CSVLink } from "react-csv";
import aptitudeSample from "../../Assets/apSample.xlsx";
import interestSample from "../../Assets/intSample.xlsx";
import sampleStudent from '../../Assets/sampleStudent.xlsx'

export default function CustomAddStudentDialogBox({ bulk, ...props }) {
  const { token_data } = useContext(UserCredsContext);
  console.log("tokeninb", token_data);
  const [open, setOpen] = React.useState(false);
  const [openPop, setopenPop] = React.useState(false);
  const [view, setView] = React.useState("0");
  const [testUploadType, setTestUploadType] = React.useState("");
  const [bulkStudentState, setBulkStudentState] = React.useState("");
  const [bulkAptitude, setBulkAptitude] = React.useState("");
  const [bulkInterest, setBulkInterest] = React.useState("");

  const handleBulkAptitutde = () => {
    if (bulkAptitude !== "") {
      // api call

      var studentBulkAp = new FormData();
      studentBulkAp.append("file", bulkAptitude);
      console.log("incomingFile", ...studentBulkAp);
      axios({
        method: "post",
        url: `${base_url}/counseling/upload_aptitute/test/admin/`,
        data: studentBulkAp,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token_data.access}`,
        },
      })
        .then((response) => {
          console.log("apititude", response);
        })
        .catch((err) => {
          console.log(err);
        });

      setOpen(false);
      return;
    } else {
      alert("File not Uploaded");
    }
  };

  const handleSample = (type) => {};

  const handleBulkInterest = () => {
    if (bulkInterest !== "") {
      // api call
      var studentBulkInt = new FormData();
      studentBulkInt.append("file", bulkInterest);
      console.log("incomingFile", ...studentBulkInt);
      axios({
        method: "post",
        url: `${base_url}/counseling/upload_interest/test/admin/`,
        data: studentBulkInt,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token_data.access}`,
        },
      })
        .then((response) => {
          console.log("interestapi", response);
        })
        .catch((err) => {
          console.log(err);
        });

      setOpen(false);
    } else {
      alert("File not Uploaded");
    }
  };

  const handleUploadBulkStudents = () => {
    if (bulkStudentState !== "") {
      console.log("apicallFor**", bulkStudentState);
      // api calll
      var studentBulk = new FormData();
      studentBulk.append("file", bulkStudentState);
      console.log("incomingFile", ...studentBulk);
      axios({
        method: "post",
        url: `${base_url}/counseling/upload_students/admin/`,
        data: studentBulk,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token_data.access}`,
        },
      })
        .then((response) => {
          console.log("students", response);
        })
        .catch((err) => {
          alert("Error!Upload failed, please try again")
          console.log(err);
        });

      setOpen(false);
    } else {
      alert("File not Uploaded");
    }
  };

  const handleIncomingFile = (file, type) => {
    if (type === "bulkStudent") {
      // api call bulk student
      // var studentBulk = new FormData();
      // studentBulk.append("key_name" , file)
      // console.log("incomingFile" , ...studentBulk);
      // axios({
      //   method:"post",
      //   url:`${base_url}`,
      //   data:studentBulk,
      //   headers:{
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${token_data.access}`,

      //   }
      // })
      // .then((response)=>{
      //   console.log(response);

      // })
      // .catch((err)=>{
      //   console.log(err);
      //   alert("Error! Please try again ")

      // })

      setBulkStudentState(file);
      return;
    }

    if (type === "bulkTestAptitude") {
      console.log("apitudeupload", file);
      setBulkAptitude(file);
      return;
    }

    if (type === "bulkTestInterest") {
      console.log("interesUpload", file);
      setBulkInterest(file);
      return;
    }
  };

  console.log("exelcheck", aptitudeSample);

  const handleClickOpen = () => {
    setOpen(true);
    setView("1");
  };

  const openDropDown = () => {
    setopenPop(true);
  };
  const closeDropDown = () => {
    setopenPop(false);
  };

  const openBulkUploadBox = (type) => {
    console.log("type", type);

    if (type === "students") {
      setOpen(true);
      setView("2");
      return;
    }
    if (type === "testResults") {
      console.log("***");
      setOpen(true);
      setView("3");
      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addStudent = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Student Details</DialogTitle>

        <DialogContent>
          <div
            style={{ marginBottom: "10px" }}
            className={styles.divider}
          ></div>
          <div className={styles.flexFields}>
            <InputField label="First Name" />
            <InputField label="Last Name" />
          </div>
          <div className={styles.flexFields}>
            <InputField label="Age" />
            <InputField label="Class" />
          </div>

          <div className={styles.flexFields}>
            <InputField label="Gender" />
            <InputField label="Father's Name" />
          </div>
          <div className={styles.flexFields}>
            <InputField label="Mother tongue" />
            <InputField label="Father's Occupation" />
          </div>
          <div className={styles.flexFields}>
            <InputField label="Mother's Ocuupation" />
            <InputField label="Parents Monthly Income" />
          </div>
          <div
            style={{ marginBottom: "10px" }}
            className={styles.divider}
          ></div>
          <div className={styles.flexFields}>
            <InputField label="Dream Career(Self)" />
            <InputField label="Parents Dream Career" />
          </div>

          <div className={styles.flexFields}>
            <InputField label="Area of Concern" />
            <InputField label="Extra Information" multiline={true} rows={3} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const addBulkStudent = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Bulk Upload Student &#40; Maximum 200 students &#41;
        </DialogTitle>

        <DialogContent>
          <DragDrop type="bulkStudent" fetchFileCallBack={handleIncomingFile} />
        </DialogContent>
        <DialogActions>
        <div className={styles.uploadBtnFlex}>
            <div>
              <Button>
                <a href={sampleStudent} download>
                  Sample report
                </a>
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUploadBulkStudents} autoFocus>
            Upload
          </Button>
            </div>
            </div>

       
        </DialogActions>
      </Dialog>
    );
  };

  const addBulkTest = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Bulk Upload Test Results
        </DialogTitle>

        <DialogContent>
          <div className={styles.btnUpload}>
            <Button
              variant="contained"
              onClick={() => {
                setView(4);
              }}
            >
              Bulk Upload Aptitude Test Details
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setView(5);
              }}
            >
              Bulk Upload Interest Test Details
            </Button>

            {/* { testUploadType == 1 &&     <DragDrop type="bulkTest" /> } */}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} autoFocus>
            Add
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  };

  const uploadDragBoxAptitude = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Bulk Upload Aptitude Results
        </DialogTitle>

        <DialogContent>
          <DragDrop
            type="bulkTestAptitude"
            fetchFileCallBack={handleIncomingFile}
          />
        </DialogContent>
        <DialogActions>
          <div className={styles.uploadBtnFlex}>
            <div>
              <Button>
                <a href={aptitudeSample} download>
                  Sample report
                </a>
              </Button>
            </div>

            <div>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleBulkAptitutde} autoFocus>
                Upload
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const uploadDragBoxInterest = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Bulk Upload Interest Results
        </DialogTitle>

        <DialogContent>
          <DragDrop
            type="bulkTestInterest"
            fetchFileCallBack={handleIncomingFile}
          />
        </DialogContent>
        <DialogActions>
          <div className={styles.uploadBtnFlex}>
            <div>
              <Button>
                <a href={interestSample} download>
                  Sample report
                </a>
              </Button>
            </div>
           
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleBulkInterest} autoFocus>
              Upload
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      {bulk ? (
        <>
          <Button
            variant="contained"
            size="small"
            onMouseOut={closeDropDown}
            onClick={openDropDown}
            sx={{ backgroundColor: "#ffffff", color: "#118a8a" }}
          >
            Bulk Upload
          </Button>
          <div
            className={
              openPop ? `${styles.displaytrue} ` : `${styles.displayNone}`
            }
          >
            <MenuItem
              value={10}
              onMouseOver={openDropDown}
              onMouseOut={closeDropDown}
              onClick={() => openBulkUploadBox("students")}
            >
              Students
            </MenuItem>
            <MenuItem
              value={20}
              onMouseOver={openDropDown}
              onMouseOut={closeDropDown}
              onClick={() => openBulkUploadBox("testResults")}
            >
              Test Result
            </MenuItem>
          </div>
        </>
      ) : (
        <Button variant="contained" size="small" onClick={handleClickOpen}>
          Add Student
        </Button>
      )}

      {view == 1 && addStudent()}
      {view == 2 && addBulkStudent()}
      {view == 3 && addBulkTest()}
      {view == 4 && uploadDragBoxAptitude()}
      {view == 5 && uploadDragBoxInterest()}
    </div>
  );
}

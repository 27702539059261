import React, { Component, createContext } from "react";
import { createTheme } from "@mui/material/styles";
import { open_api , token_api } from "../../Utils/Network";
export const UserCredsContext = createContext();

const localstorage_state = localStorage.getItem(
  "brainhapAdmin_user_cred_context"
);

function getLocalData(keyname) {
  // Function to return values from local storage

  let object = null;

  try {
    object = JSON.parse(localstorage_state);
  } catch {
    console.error("There was error while parsing data from localstorage.");
  }

  if (object) {
    if (object[keyname]) {
      return object[keyname];
    }
  }

  if (keyname === "themeMode") return "dark";
  if (keyname === "user") return {};
  if (keyname === "token") return { access: "", refresh: "" };
  if (keyname === "nftData") return {};
  return "";
}

class UserCredsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: createTheme({
        palette: {
          primary: { 500: "#118a8a" },
          secondary: { A400: "#D78312" },
          white: { 500: "rgba(255,255,255)" },
          mode: "light",
        },
      }),
      col: {
        primary: "#FB9B5F",
        secondary: "#143F6B",
        ternary: "#006080",
      },
      content_selection: {
        // board: null,
        // subject: null,
        // grades: null,
        course: null,
      },
      grade: [],
      institute:[],
      themeMode: getLocalData("themeMode"),
      user_state: getLocalData("user_state"),
      token: getLocalData("token"),
      user: getLocalData("user"),
      urls: '',
    };
    // this.setThemeMode = this.setThemeMode.bind(this);

    this.getGradeListing = this.getGradeListing.bind(this);
    this.getInstituteListing = this.getInstituteListing.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setToken = this.setToken.bind(this);
    this.setUrl = this.setUrl.bind(this);
    this.setContent = this.setContent.bind(this);
  }

  componentDidMount() {
    this.getGradeListing();
    this.getInstituteListing();
   
    window.addEventListener("beforeunload", () => {
      localStorage.setItem(
        "brainhapAdmin_user_cred_context",
        JSON.stringify(this.state)
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user_state !== this.state.user_state) {
      localStorage.setItem(
        "brainhapAdmin_user_cred_context",
        JSON.stringify(this.state)
      );
    }
  }

  componentWillUnmount() {}

  getGradeListing = () => {
    open_api.get(`/base/grade`).then((response) => {
      this.setState({ grade: response.data.data });
    });
  };

  getInstituteListing = () => {
    open_api.get(`base/institute/listing`).then((response) => {
      this.setState({institute: response.data.data})
    })
  }

  setUserState = (user_data, token_data) => {
    this.setState({ user_state: user_data, token: token_data });
  };

  setToken(_token) {
   
    this.setState({ token: _token });
  }
  setUser(_userData) {
    this.setState({
      user: _userData,
    });
  }

  setContent = (content) => {
    this.setState({ content_selection: content })
  }

  setUrl(urls){
    this.setState({ urls: urls});
  }

  logout = () => {
    this.setState(
      {
        // themeMode: "dark",
        user: {},
        token: { access: "", refresh: "" },
        urls: "",
      },
      () => {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      }
    );
  };

  render() {
    const { grade ,institute } = this.state;
    
    return (
      <UserCredsContext.Provider
        value={{
          theme: this.state.theme,
          col: this.state.col,
          themeMode: this.state.themeMode,
          // setUserState: this.setUserState,
          content_selection: this.state.content_selection,
          logout: this.logout,
          userState: this.state.user_state,
          token_data: this.state.token,
          setContent: this.setContent,
          setToken: this.setToken,
          setUser: this.setUser,
          gradeList: grade,
          instituteList: institute,
          setUrl: this.setUrl,
          urls: this.state.urls,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;

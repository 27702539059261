export function helperDateReverese(date_) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }

  var d = new Date(date_);
  console.log("Incomingdatae", d);
  return [pad(d.getFullYear()), pad(d.getMonth() + 1), pad(d.getDate())].join(
    "-"
  );
}

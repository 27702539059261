import React from "react";
import styles from "./index.module.css";
import CustomTable from "../../Components/CustomTable/CustomTable";
import DrawerComponent from "../../Components/DrawerComponent/DrawerComponent";
import { Button, Pagination, TextField } from "@mui/material";
import { token_api } from "../../Utils/Network";
import { useEffect, useState } from "react";
import AddSchool from "./AddSchool";
import SearchIcon from "@mui/icons-material/Search";
import CustomModel from "../../Components/CustomModel/CustomModel";
import editIcon from "../../Assets/EditIcon.svg"
import deleteIcon from "../../Assets/DeleteIcon.svg"
import { Grade } from "@mui/icons-material";
import AssignCoursesModel from "../../Components/AssignCoursesModel/AssignCoursesModel";

const Schools = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [opneModel, setOpneModel] =useState(false);
  const [opneCoursesModel, setOpneCoursesModel] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [schoolList, setSchoolList] = useState([]);
  const [filterData, setFilterData] = useState({
    q: "",
  });
  const [selectSchoolId,setselectSchoolId] = useState("")
  const[selectdata, setSelectData] = useState("")
  const [editState, setEditState] = useState(false);
  const [editObject, setEditObject] = useState({});
  const [paginationData, setPaginationData] = useState({});
  const [pageState, setPageState] = useState(1);
  const [languageList, setLanguageList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [showEditsection, setShowEditsection] =useState(false);
  const [avaiSectionData, setAvaiSectionData] = useState("")
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const handleEditInstitute = (id,user) => {
    token_api
      .get(`profile/V2/institute/listing/${id}/`)
      .then((response) => {
        if (response?.status == 200) {
          const {
            id,
            name,
            primary_contact,
            secondary_contact,
            location,
            website,
            since,
            email_id,
            board,
            language,
            grade,
            school_medium,
          } = response?.data?.data;

          console.log("ASASDS",  language );

          let langObj = language.map((l) => {
            for (let i of languageList) {
              if (l == i?.id) {
                return i;
              }
            }
          });
          let boardObj = board.map((l) => {
            for (let i of boardList) {
              if (l == i?.id) {
                return i;
              }
            }
          });
          let gradeObj = grade.map((l) => {
            for (let i of gradeList) {
              if (l.id == i?.id) {
                return i;
              }
            }
          });

          console.log("tyyetwyetw", gradeObj);

          setEditObject({
            ...editObject,
            id,
            name,
            email_id,
            primary_contact,
            secondary_contact,
            location,
            website,
            since,
            school_medium,
            user,
            language_id: langObj || [],
            board_id: boardObj || [],
            grade_id: gradeObj || [],
          });
          setEditState(true);
          setOpenDrawer(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddSections = (id, grade, section) =>{
    console.log(id, grade,section, "handleAddSectionsid");
    setOpneModel(true)
    setselectSchoolId(id)
    setSelectData(grade)   
    setAvaiSectionData(section)
  }

  const handleAddCourse = (id,section) =>{
    console.log(id);
    setOpneCoursesModel(true)
    setselectSchoolId(id)
    setAvaiSectionData(section)
  }

  const getAllSchoolListing = ({ paramsObj = { ...filterData } }) => {
    setIsLoading(true);
    token_api
      .get(`profile/V2/institute/listing`, { params: { ...paramsObj } })
      .then((response) => {
        console.log("ChekResponse", response);
        const { data } = response?.data;
        let modifiedArray = data?.map?.((school) => {
          const { id, name, location, primary_contact, user,grade,section } = school;
          return {
            id: id || "N.A",
            school: name || "N.A",
            location: location || "N.A",
            contact: primary_contact || "N.A",
            section:(
              <div style={{cursor:"pointer"}} onClick={() => handleAddSections(id,grade, section)}>
                {showEditsection ? "Edit sections" : "Add sections"}
              </div>
            ),
            course:(
              <div style={{cursor:"pointer"}} onClick={() => handleAddCourse(id, section)}>
                Assign Courses
              </div> 
            ),
            actions: (
              <div>
                {/* <span style={{cursor:"pointer"}} onClick={() => handleEditInstitute(id,user)}>Edit</span> */}
                <span style={{cursor:"pointer", marginLeft:"20px"}} onClick={() => handleEditInstitute(id,user)}><img src={editIcon}/></span>
                {/* <span>{`/`}</span> */}
                {/* <span style={{cursor:"pointer"}}>Delete</span> */}
                {/* <span style={{cursor:"pointer"}}><img src={deleteIcon} /></span> */}
              </div>
            ),
          };
        });

        setSchoolList([...modifiedArray]);
        setPaginationData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBoardList = () => {
    token_api
      .get(`base/board/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setBoardList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLanguageList = () => {
    token_api
      .get(`base/language/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setLanguageList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getGradeList = () => {
    token_api
      .get(`base/grade/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setGradeList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("asdasdsdsdddgdfggradeList", gradeList);
  useEffect(() => {
    const timeout = setTimeout(() => {
      let obj = filterData;
      obj.page = pageState;
      getAllSchoolListing(obj);
    }, 600);
    return () => clearTimeout(timeout);
  }, [pageState, filterData?.q]);

  useEffect(() => {
    getBoardList();
    getLanguageList();
    getGradeList();
  }, []);

  return (
    <>
      <div className={`${styles.conatiner}`}>
        {/* <div>School</div> */}
        <div className={`${styles.filterBox}`}>
          <div>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              value={filterData?.name}
              onChange={(v) => {
                setFilterData({
                  ...filterData,
                  q: v.target.value,
                });
              }}
              // onChange={(v) => {
              //   let coach = v.target.value;
              //   let obj = filterData;
              //   if (coach) {
              //     obj.q = coach;
              //     obj.page = 1;
              //   } else {
              //     delete obj.q;
              //   }
              //   getStudentList(obj);
              // }}
            />
          </div>
          <div className={`${styles.btnFlex}`}>
            <div>
              <Button variant="contained" onClick={() => setOpenDrawer(true)}>
                Add School
              </Button>
            </div>
            {/* <div>
              <Button variant="contained">Bulk Upload</Button>
            </div> */}
          </div>
        </div>
        <div className={`${styles.tableBox}`}>
          <CustomTable
            columns={columns}
            data={schoolList}
            isLoading={isLoading}
          />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Pagination
            count={max_pages}
            variant="outlined"
            shape="rounded"
            onChange={(e, value) => setPageState(value)}
          />
        </div>

        <div>
          <DrawerComponent
            open={openDrawer}
            anchor={"right"}
            onClose={() => {
              setOpenDrawer((prev) => !prev);
              setEditState(false);
              setEditObject({});
            }}
          >
            <AddSchool
              setOpenDrawer={setOpenDrawer}
              setEditState
              setEditObject
              editState={editState}
              editObject={editObject}
            />
          </DrawerComponent>
        </div>
        <div>
          <CustomModel
          open ={opneModel}
          fullwidth={100}
          onClose={(() => {
            setOpneModel((prev) => !prev);
          })}
          setOpneModel={setOpneModel}
          selectdata={selectdata}
          schoolId={selectSchoolId}
          showEditsection={showEditsection}
          sectionData={avaiSectionData}
          >

          </CustomModel>
        </div>
        <div>
          <AssignCoursesModel 
          open={opneCoursesModel}
          onClose={(() => {
            setOpneCoursesModel((prev) => !prev);
          })}
          setOpneCoursesModel={setOpneCoursesModel}
          schoolID={selectSchoolId}
          editState={editState}
              editObject={editObject}
              showSections={avaiSectionData}
          >
            
          </AssignCoursesModel>
        </div>
      </div>
    </>
  );
};

export default Schools;

const columns = [
  { id: "id", label: "ID" },
  { id: "school", label: "School" },
  { id: "location", label: "Location" },
  { id: "contact", label: "Contact" },
  { id: "section" , label: "Section"},
  { id: "course" , label: "Course"},
  { id: "actions", label: "Actions" },

  // Add more columns as needed
];

import React, { useEffect, useContext } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { UserCredsContext } from "../ContextApi/UserCredsContext/UserCredsContext";
import Dashboard from "../Dashboard";
import Authentication from '../Authentication/Authentication'

function AppRouter() {
  const { theme } = useContext(UserCredsContext);
  let navigate = useNavigate();
  const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/") {
        navigate("/login");
      }
    }, []);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
      <Route strict exact path="/login" element={<Authentication />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </ThemeProvider>
  );
}

export default AppRouter;

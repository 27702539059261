import React, { useContext } from "react";
import styles from "./index.module.css";
import CustomTable from "../../Components/CustomTable/CustomTable";
import DrawerComponent from "../../Components/DrawerComponent/DrawerComponent";
import { Button, Pagination, TextField } from "@mui/material";
import { token_api } from "../../Utils/Network";
import { useEffect, useState } from "react";
import AddTeacher from "./AddTeacher";
import SearchIcon from "@mui/icons-material/Search";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import editIcon from "../../Assets/EditIcon.svg"
import deleteIcon from "../../Assets/DeleteIcon.svg"

const Teachers = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [teacherList, setTeacherList] = useState([]);
  const [filterData, setFilterData] = useState({
    q: "",
  });
  const [editState, setEditState] = useState(false);
  const [editObject, setEditObject] = useState({});
  const [paginationData, setPaginationData] = useState({});
  const [pageState, setPageState] = useState(1);
  // const [languageList, setLanguageList] = useState([]);
  // const [gradeList, setGradeList] = useState([]);
  const { instituteList, gradeList } = useContext(UserCredsContext);
  const [boardList, setBoardList] = useState([]);
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const handleEditTeacher = (id) => {
    token_api
      .get(`profile/v1/teachers/${id}`)
      .then((res) => {
        if (res?.status === 200) {
          const {
            id,
            // user: { first_name, last_name, middle_name, phone, email,gender, id },
            user,
            // last_name,
            // middle_name,
            // phone,
            // email,
            place_of_birth,
            dob,
            grade,
            subject,
            institute,
            education,
            // occupation:{company},
            occupation
            // gender,
          } = res?.data?.data;
          // let subjectObj = subject.map((l) => {
          //   for (let i of gradeList) {
          //     if (l == i?.id) {
          //       return i;
          //     }
          //   }
          // });
          // let gradeObj = grade.map((l) => {
          //   for (let i of gradeList) {
          //     if (l == i?.id) {
          //       return i;
          //     }
          //   }
          // });

          setEditObject({
            ...editObject,
            id:{mainId :id,
            userId:user?.id},
            gender:user?.gender,
            first_name:user?.first_name ,
            // id:user?.id,
            last_name:user?.last_name,
            middle_name: user?.middle_name,
            phone:user?.phone,
            email:user?.email,
            place_of_birth,
            dob,
            subject_id: subject || [],
            grade_id: grade || [],
            institute,
            education,
            occupation,
            // company:company,
          });
          setEditState(true);
          setOpenDrawer(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTeacherListing = (paramsObj) => {
    setIsLoading(true);
    token_api
      .get(`profile/v1/teachers/`, { params: { ...paramsObj } })
      .then((response) => {
        const { data } = response?.data;
        let modifiedArray = data?.map?.((school) => {
          const {
            id,
            // first_name,
            // last_name,

            user: { phone, first_name, last_name },
          } = school;
          return {
            id: id || "N.A",
            name: `${first_name || ""} ${last_name || ""}`,
            // location: location || "N.A",
            contact: phone || "N.A",
            actions: (
              <div>
                 <span style={{cursor:"pointer", marginLeft:"20px"}} onClick={() => handleEditTeacher(id)}><img src={editIcon}/></span>
                {/* <span onClick={() => handleEditTeacher(id)}>Edit</span>
                <span>{`/`}</span>
                <span>Delete</span> */}
              </div>
            ),
          };
        });

        setTeacherList([...modifiedArray]);
        setPaginationData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let obj = filterData;
      obj.page = pageState;

      getAllTeacherListing(obj);
    }, 600);
    return () => clearTimeout(timeout);
  }, [pageState, filterData?.q]);

  return (
    <>
      <div className={`${styles.conatiner}`}>
        {/* <div>School</div> */}
        <div className={`${styles.filterBox}`}>
          <div>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              value={filterData?.name}
              onChange={(v) => {
                setFilterData({
                  ...filterData,
                  q: v.target.value,
                });
              }}
              // onChange={(v) => {
              //   let coach = v.target.value;
              //   let obj = filterData;
              //   if (coach) {
              //     obj.q = coach;
              //     obj.page = 1;
              //   } else {
              //     delete obj.q;
              //   }
              //   getStudentList(obj);
              // }}
            />
          </div>
          <div className={`${styles.btnFlex}`}>
            <div>
              <Button variant="contained" onClick={() => setOpenDrawer(true)}>
                Add Teacher
              </Button>
            </div>
            {/* <div>
              <Button variant="contained">Bulk Upload</Button>
            </div> */}
          </div>
        </div>
        <div className={`${styles.tableBox}`}>
          <CustomTable
            columns={columns}
            data={teacherList}
            isLoading={isLoading}
          />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Pagination
            count={max_pages}
            variant="outlined"
            shape="rounded"
            onChange={(e, value) => setPageState(value)}
          />
        </div>

        <div>
          <DrawerComponent
            open={openDrawer}
            anchor={"right"}
            onClose={() => {
              setOpenDrawer((prev) => !prev);
              setEditState(false);
              setEditObject({});
            }}
          >
            <AddTeacher
              setOpenDrawer={setOpenDrawer}
              setEditState
              setEditObject
              editState={editState}
              editObject={editObject}
            />
          </DrawerComponent>
        </div>
      </div>
    </>
  );
};

export default Teachers;

const columns = [
  { id: "id", label: "ID" },
  { id: "name", label: "Teacher name" },
  { id: "contact", label: "Contact" },
  { id: "actions", label: "Actions" },

  // Add more columns as needed
];

import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["XLSX", "CSV"];

function DragDrop({ type, fetchFileCallBack, ...props }) {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    console.log("typeinDrag", type);
    setFile(file);

    if (type === "bulkStudent") {
      fetchFileCallBack(file, "bulkStudent");
      return;
    }

    if (type === "bulkTestAptitude") {
      fetchFileCallBack(file, "bulkTestAptitude");
      return;
    }
    if (type === "bulkTestInterest") {
      fetchFileCallBack(file, "bulkTestInterest");
      return;
    }
  };

  console.log("seefile", file);

  const openBoxForTest = () => {
    return (
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
    );
  };

  const openBoxForStudents = () => {
    return (
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
    );
  };
  return (
    <>
      {type === "bulkTestAptitude" || type === "bulkTestInterest" ? (
        openBoxForTest()
      ) : (
        <></>
      )}
      {type === "bulkStudent" && openBoxForStudents()}{" "}
    </>
  );
}

export default DragDrop;

import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { token_api } from '../../Utils/Network';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const color = {
    color: "#118a8a"
}
export default function DeleteModal({ open, closePopup, data, getAllSchoolListing }) {
    const [deleteData, setDeleteData] = React.useState()




    const getData = () => {
        token_api
            .delete(`counseling/mindreports/${data.id}/`)
            .then((response) => {
                if(response?.status === 204 || response?.status === 200 || response?.status === 201) 
                // setDeleteData(response.data?.data)
                closePopup()
                getAllSchoolListing({q: "", page:1, });


            })
            .catch((error) => console.log(error));
    };




    // React.useEffect(() => {
    //     if (data.id) {

    //         getData()
    //     }
    // }, [data.id])






    console.log(open, "hkjhhk");
    return (
        <div>
            <Modal
                open={open}
                onClose={closePopup}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"

            >
                <Box sx={{ ...style, width: 400, borderRadius: "10px", textAlign: "center" }}>
                    <h2 id="parent-modal-title">
                        
                        {/* You wont to Delete<br />Id<span style={{ ...color }} > {data.id}</span>  Name <span style={{ ...color }}> {data.name}</span> Data */}
                  
                  Are you sure to delete 
                  <br/>this data  </h2>

                    <div style={{ display: "flex", justifyContent: "space-evenly" }} >

                        <Button variant='contained' onClick={closePopup}>no</Button>
                        <Button variant='contained' onClick={getData} >yes</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

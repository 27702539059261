import React, { useContext } from "react";
import styles from "./index.module.css";
import InputField from "../../Components/Input/InputFields";
import { Button, TextField } from "@mui/material";
import CustomSelect from "../../Components/CustomSelect/CustomSelect";
import { useState } from "react";
import { useEffect } from "react";
import { token_api } from "../../Utils/Network";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { helperDateReverese } from "../../Utils/helper";
import CustomMultiSelectChips from "../../Components/CustomMultiSelectChips/CustomMultiSelectChips";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
// import CheckBox from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
// import { CheckBox } from "@mui/icons-material";
// import Checkbox from "@mui/material";

const AddTeacher = ({
  setOpenDrawer,
  editState,
  editObject,
  setEditState,
  setEditObject,
}) => {
  const [teacherData, setTeacherData] = useState({
    id: null,
    first_name: "",
    last_name: "",
    middle_name: "",
    phone: "",
    email: "",
    dob: null,
    place_of_birth: "",
    subject_id: [],
    institute: "",
    school_medium: "",
    grade_id: [],
    gender: 1,
    since: null,
    degree: "",
    specialization: "",
    passing_year: "",
    company: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const [languageList, setLanguageList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [detailsTab, setDetailsTab] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [isCurrentJob, setIsCurrentJob] = useState(false);
  const [profInputField, setProfInputField] = useState([
    {
      profession: "",
      company: "",
      start_date: null,
      is_current_job: false,
      end_date: null,
      position: "",
    },
  ]);
  const [eduInputField, setEduInputField] = useState([
    {
      institute: "",
      city: "",
      degree: "",
      specialization: "",
      start_date: null,
      end_date: null,
    },
  ]);

  const { instituteList } = useContext(UserCredsContext);
  console.log(editObject, "instituteListforaddT");

  const validate = (value) => {
    console.log("valalal", value);
    const {
      first_name,
      last_name,
      middle_name,
      phone,
      email,
      dob,
      subject_id,
      institute,
      place_of_birth,
    } = teacherData;
    const errors = {
      error: false,
    };

    if (!first_name) {
      errors.first_name = "Enter first name";
      errors.error = true;
    }

    if (!last_name) {
      errors.last_name = "Enter last name";
      errors.error = true;
    }

    if (!phone) {
      errors.phone = "Enter phone number";
      errors.error = true;
    }

    if (!email) {
      errors.email = "Enter email";
      errors.error = true;
    }

    setFormErrors(errors);
    return errors;
  };

  const handleAddTeacher = () => {
    console.log("EDDIDIDI", editState);

    let validateResults = validate(teacherData);

    const { error } = validateResults;
    if (error) return;

    if (editState) {
      let langIds = teacherData?.language_id?.map((l, i) => {
        return l?.id;
      });
      let boardIds = teacherData?.board_id?.map((l, i) => {
        return l?.id;
      });

      let gradeIds = teacherData?.grade_id?.map((l, i) => {
        return l?.id;
      });
      let subjectIds = teacherData?.subject_id?.map((l, i) => {
        return l?.id;
      });
      // let data = {
      //   user_id: teacherData?.id,
      //   institute_profile: {
      //     language_id: langIds,
      //     board_id: boardIds,
      //     grade_id: gradeIds,
      //     name: teacherData?.name,
      //     email_id: teacherData?.email_id,
      //     primary_contact: teacherData?.primary_contact,
      //     secondary_contact: teacherData?.secondary_contact,
      //     school_medium: teacherData?.school_medium,
      //     location: teacherData?.location,
      //     website: teacherData?.website,
      //     since: helperDateReverese(teacherData?.since),
      //   },
      // };
      let data = {
        user: {
          id: teacherData?.id?.userId,
          first_name: teacherData?.first_name,
          last_name: teacherData?.last_name,
          middle_name: teacherData?.middle_name,
          phone: teacherData?.phone,
          email: teacherData?.email,
          gender: teacherData?.gender,
        },
        first_name: teacherData?.first_name,
        dob: helperDateReverese(teacherData?.dob),
        place_of_birth: teacherData?.place_of_birth,
        gender: teacherData?.gender,
        grade_id: gradeIds,
        subject_id: subjectIds,
        institute: teacherData?.institute,
        education: [
          {
            degree: teacherData?.degree,
            specialization: teacherData?.specialization,
            passing_year: teacherData?.passing_year,
          },
        ],
        occupation: [
          {
            company: teacherData?.company,
          },
        ],
      };
      console.log(data, "editteacherData");
      token_api
        .patch(`profile/v1/teachers/${teacherData?.id?.mainId}/`, data)
        .then((response) => {
          console.log("UUUUUUU", response);
          setOpenDrawer(false)
        })
        .catch((err) => {
          console.log(err);
          alert("Error in updating school.");
        });
    } else {
      let langIds = teacherData?.language_id?.map((l, i) => {
        return l?.id;
      });
      let boardIds = teacherData?.board_id?.map((l, i) => {
        return l?.id;
      });

      let gradeIds = teacherData?.grade_id?.map((l, i) => {
        return l?.id;
      });
      let subjectIds = teacherData?.subject_id?.map((l, i) => {
        return l?.id;
      });

      // let data = {
      //   institute_profile: {
      //     language_id: langIds,
      //     board_id: boardIds,
      //     grade_id: gradeIds,
      //     name: teacherData?.name,
      //     email_id: teacherData?.email_id,
      //     primary_contact: teacherData?.primary_contact,
      //     secondary_contact: teacherData?.secondary_contact,
      //     school_medium: teacherData?.school_medium,
      //     location: teacherData?.location,
      //     website: teacherData?.website,
      //     since: helperDateReverese(teacherData?.since),
      //   },
      // };
      let data = {
        user: {
          first_name: teacherData?.first_name,
          last_name: teacherData?.last_name,
          middle_name: teacherData?.middle_name,
          phone: teacherData?.phone,
          email: teacherData?.email,
          gender: teacherData?.gender,
          user_type: 2,
        },
        dob: helperDateReverese(teacherData?.dob),
        place_of_birth: teacherData?.location,
        gender: teacherData?.gender,
        grade_id: gradeIds,
        subject_id: subjectIds,
        institute: teacherData?.institute,
        education: [
          {
            degree: teacherData?.degree,
            specialization: teacherData?.specialization,
            passing_year: teacherData?.passing_year,
          },
        ],
        occupation: [
          {
            company: teacherData?.company,
          },
        ],
      };

      console.log(data, "afteraddthedata");
      // if (data !== null) {
      //   setDetailsTab(false);
      // }

      token_api
        .post(`profile/v1/teachers/`, data)
        .then((response) => {
          console.log("CrateSchoolResponse", response?.data?.data?.user?.id);
          if (response?.status == 200 || response?.status == 201) {
            setResponseData(response?.data?.data?.user?.id);
            // setDetailsTab(false);
            setOpenDrawer(false);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Error in creating school");
        });
    }
  };

  const handleChangeChips = (e, chipType) => {
    // const { value } = e.target;
    console.log("jdfjkdjksdkj", e);

    if (chipType === "lang") {
      setTeacherData({
        ...teacherData,
        language_id: typeof e === "string" ? e.split(",") : e,
      });
    }
    if (chipType === "board") {
      setTeacherData({
        ...teacherData,
        board_id: typeof e === "string" ? e.split(",") : e,
      });
    }
    if (chipType === "grade") {
      setTeacherData({
        ...teacherData,
        grade_id: typeof e === "string" ? e.split(",") : e,
      });
    }
    if (chipType === "subject") {
      setTeacherData({
        ...teacherData,
        subject_id: typeof e === "string" ? e.split(",") : e,
      });
    }
  };

  const handleDeleteChips = (id, chipType) => {
    console.log("poli", id, chipType);
    if (chipType === "lang") {
      let storeVal = teacherData?.language_id.filter((chip) => chip.id !== id);
      console.log("DelPayload", storeVal);
      setTeacherData({
        ...teacherData,
        language_id: storeVal,
      });
    }
    if (chipType === "board") {
      let storeVal = teacherData?.board_id.filter((chip) => chip.id !== id);
      setTeacherData({
        ...teacherData,
        board_id: storeVal,
      });
    }
    if (chipType === "grade") {
      let storeVal = teacherData?.grade_id.filter((chip) => chip.id !== id);
      setTeacherData({
        ...teacherData,
        grade_id: storeVal,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "degree") {
      let edu = {
        [name]: value,
      };
      console.log("DDDD", edu);
      setTeacherData({
        ...teacherData,
        education: { ...edu },
      });
    }
    setTeacherData({
      ...teacherData,
      [name]: value,
    });
  };

  console.log("teacherData", teacherData);

  const getBoardList = () => {
    token_api
      .get(`base/board/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setBoardList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLanguageList = () => {
    token_api
      .get(`base/language/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setLanguageList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("asdasdsdsdddgdfg", languageList);
  const getGradeList = () => {
    token_api
      .get(`base/grade/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setGradeList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubjectList = () => {
    token_api
      .get(`base/subject/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setSubjectList([...modifiedArray]);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  console.log(subjectList, "subjectList");
  useEffect(() => {
    // all listing calls
    getBoardList();
    getLanguageList();
    getGradeList();
    getSubjectList();
  }, []);

  useEffect(() => {
    if (editState) {
      setTeacherData({ ...editObject });
    }
  }, []);

  console.log("edieiei", editState);
  // //////////////////////////// add More Data ///////////////////
  const handleAddProfField = (e) => {
    // const {name , value} = e.target
    // setProfInputField([{
    //   ...profInputField,
    //   [name]: value,
    // }]);
    setProfInputField((prev) => [
      ...prev,
      {
        profession: "",
        company: "",
        start_date: null,
        is_current_job: false,
        end_date: null,
        position: "",
      },
    ]);
  };

  const handleAddEduField = () => {
    // setEduInputField([
    //   {
    //     institute: "",
    //     city: "",
    //     degree: "",
    //     specialization: "",
    //     start_date: "",
    //     end_date: "",
    //   },
    // ]);
    setEduInputField((prev) => [
      ...prev,
      {
        institute: "",
        city: "",
        degree: "",
        specialization: "",
        start_date: null,
        end_date: null,
      },
    ]);
  };

  const handleProfChange = (id, e) => {
    
    const updatedProfInputField = [...profInputField];
    const { name, value, checked } = e.target;
    // Update the specific field for the given index
    if (name === "is_current_job") {
      // Update the specific profession's "is_current_job" value
      updatedProfInputField[id] = {
        ...updatedProfInputField[id],
        is_current_job: checked,
      };
    } else {
      updatedProfInputField[id] = {
        ...updatedProfInputField[id],
        [name]: value,
      };
    }

    // Set the updated array as the new state
    setProfInputField(updatedProfInputField);

    console.log(updatedProfInputField, "handleProfChange");
  };

  const handleEduChange = (id, e) => {
    
    const updatedProfInputField = [...eduInputField];
    const { name, value, checked } = e.target;
    // Update the specific field for the given index
    if (name === "is_current_job") {
      // Update the specific profession's "is_current_job" value
      updatedProfInputField[id] = {
        ...updatedProfInputField[id],
        is_current_job: checked,
      };
    } else {
      updatedProfInputField[id] = {
        ...updatedProfInputField[id],
        [name]: value,
      };
    }

    // Set the updated array as the new state
    setEduInputField(updatedProfInputField);

    console.log(updatedProfInputField, "handleEduChange");
  };
  return (
    <>
      {/* {detailsTab ? ( */}
        <div className={`${styles.addContainer}`}>
          <div className={`${styles.titleStyles}`}>Add Teacher</div>
          <div className={`${styles.formBox}`}>
            <InputField
              label={"First Name"}
              onChange={handleChange}
              name="first_name"
              value={teacherData?.first_name}
              error={formErrors?.first_name}
              helperText={formErrors?.first_name}
            />
            {/* <InputField
            label={"Middle Name"}
            onChange={handleChange}
            name="middle_name"
            value={teacherData?.middle_name}
            error={formErrors?.middle_name}
            helperText={formErrors?.middle_name}
          /> */}
            <InputField
              label={"Last Name"}
              onChange={handleChange}
              name="last_name"
              value={teacherData?.last_name}
              error={formErrors?.last_name}
              helperText={formErrors?.last_name}
            />
            <InputField
              label={"Phone"}
              onChange={handleChange}
              name="phone"
              value={teacherData?.phone}
              error={formErrors?.phone}
              helperText={formErrors?.phone}
            />
            <InputField
              label={"Email"}
              onChange={handleChange}
              name="email"
              value={teacherData?.email}
              error={formErrors?.email}
              helperText={formErrors?.email}
            />

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="DOB"
                  inputFormat="MM/DD/YYYY"
                  value={teacherData?.dob}
                  onChange={(newValue) => {
                    setTeacherData({
                      ...teacherData,
                      dob: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div>
              <div className={styles.flexFields}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="gender"
                    value={teacherData?.gender}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Female"
                      onChange={(e) => handleChange(e, "userObject")}
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Male"
                      onChange={(e) => handleChange(e, "userObject")}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <InputField
              label={"Place of birth"}
              onChange={handleChange}
              name="place_of_birth"
              value={teacherData?.place_of_birth}
              error={formErrors?.place_of_birth}
              helperText={formErrors?.place_of_birth}
            />

            <div>
              <CustomMultiSelectChips
                listArray={gradeList}
                label={"Grade"}
                name="grade_id"
                onChange={(e) => handleChangeChips(e, "grade")}
                handleDelete={(id) => handleDeleteChips(id, "grade")}
                value={teacherData?.grade_id}
                size={"small"}
                error={formErrors?.grade}
              />
            </div>
            <div>
              <CustomMultiSelectChips
                listArray={subjectList}
                label={"Subject"}
                name="subject_id"
                onChange={(e) => handleChangeChips(e, "subject")}
                handleDelete={(id) => handleDeleteChips(id, "subject")}
                value={teacherData?.subject_id}
                size={"small"}
                error={formErrors?.subject_id}
              />
            </div>

            {/* <div>
            <CustomSelect
              listarray={subjectList}
              label={"Subject"}
              name="subject_id"
              onChange={handleChange}
              value={teacherData?.subject_id}
              size={"small"}
              // error={formErrors?.language}
            />
          </div> */}
            <div>
              <CustomSelect
                // listarray={languageList}
                listarray={instituteList}
                label={"Institute"}
                name="institute"
                onChange={handleChange}
                value={teacherData?.institute}
                size={"small"}
                error={formErrors?.language}
              />
            </div>
          </div>
          <div className={`${styles.btnContainer}`}>
            <div>
              <Button variant="contained" onClick={handleAddTeacher}>
                Add
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenDrawer((prev) => !prev);
                  setEditState(false);
                  setEditObject({});
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      {/* ) : ( */}
      
      {/* )} */}

      {/* <div style={{ border: "1px solid #000" }}></div>
          <p>Professional Details</p>
          <div className={`${styles.formBox}`}>
            <InputField
              label={"Degree"}
              onChange={handleChange}
              name="degree"
              value={teacherData?.degree}
              error={formErrors?.degree}
              helperText={formErrors?.degree}
            />
            <InputField
              label={"Specialization"}
              onChange={handleChange}
              name="specialization"
              value={teacherData?.specialization}
              error={formErrors?.specialization}
              helperText={formErrors?.specialization}
            />
            <InputField
              label={"Passing Year"}
              onChange={handleChange}
              name="passing_year"
              value={teacherData?.passing_year}
              error={formErrors?.passing_year}
              helperText={formErrors?.passing_year}
            />
            <InputField
              label={"Company"}
              onChange={handleChange}
              name="company"
              value={teacherData?.company}
              error={formErrors?.company}
              helperText={formErrors?.company}
            />
          </div> */}
    </>
  );
};

export default AddTeacher;


const eduprof =[
  {
    id:1,
    profession:"Software Developer",
    company:"g10",
    start_date:"03-10-2003",
    end_date:"07-11-2005",

  }
]
import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Input, TextField } from "@mui/material";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import styles from "./custom.module.css";
import { token_api } from "../../Utils/Network";

const CustomModel = ({
  open,
  fullWidth,
  onClose,
  schoolId,
  selectdata,
  sectionData,
  setOpneModel,
  showEditsection,
}) => {
  const [boardType, setBoardType] = useState();
  const [classList, setClassList] = useState([]);
  const [boolean, setBoolean] = useState(true);
  const [gradeData, setGradeData] = useState({});

  console.log(sectionData, "cdsdsvvvddddddg");
  // let takeInputValue = selectdata?.length;
  // const [inputSectionData, setInputSectionData] = useState({
  //   number:null,
  // })

  const { gradeList } = useContext(UserCredsContext);
  let char = ["A", "B", "C", "D", "E", "F", "G", "H"];
  // const navigate = useNavigate();
  const handleBoard = (value) => {
    setBoardType(value);
  };
  //   useEffect(() =>{
  //     if(sectionData?.length > 0){
  //         setInputSectionData((prev) => ({...prev, number:sectionData?.length  }))
  //     }

  // },[])
  useEffect(() => {
    if (
      classList.length === gradeList.length &&
      classList.every((obj) => obj.class.length)
    ) {
      setBoolean(false);
    } else {
      setBoolean(true);
    }
  }, [classList]);

  useEffect(() => {
    // Make API requests or perform actions based on the schoolId
    console.log("Selected School ID:", schoolId);
  }, [schoolId]);

  console.log(selectdata, "gradeList");

  // const handleChange = (e, value) => {
  //   const data = {
  //       id: value.id,
  //       class: [],
  //       // sections: [],
  //   }
  //   for (let i = 0; i < e.target.value; i++) {
  //       let newValue = {
  //           grade_id: i,
  //           institute_id: schoolId,
  //           title: `${value.title}-${char[i]}`
  //       }
  //       data.class.push(newValue)
  //       // data.push(newValue)
  //   }
  //   setGradeData((prevData) => ({
  //     ...prevData,
  //     [value.id]: data,
  //   }));

  //   let ind = classList?.findIndex((i) => {
  //       return i.id == data?.sections?.id
  //   })
  //   // setClassList([...classList]);
  //   if (ind === -1) {
  //       setClassList([...classList, data])
  //       // setBoolean(false)
  //   } else {
  //       classList[ind] = data;
  //       setClassList([...classList]);
  //       // setBoolean(false)
  //   }
  //   // console.log(data, "classListLength");
  //   if (classList){
  //     console.log(classList, "classListLength");
  //     setBoolean(false)
  //   }
  // }
  // const getClassListValue = (id) => {
  //   const value = sectionData.find(info => (info.id === id) )
  //   console.log(value,"getClassListValue");
  // }
  // getClassListValue(16)
  const handleChange = (e, value) => {
    let takeInputValue = e.target.value;
    console.log(value, "jehfuyahdbakhdgakdhakdha");
    // setInputSectionData((prev) => ({...prev, number:takeInputValue  }))
    const data = {
      id: value.id,
      class: [],
    };
    for (let i = 0; i < takeInputValue; i++) {
      let newValue = {
        ids: i,
        grade: value.id,
        title: `${value.title} ${char[i]}`,
      };
      data.class.push(newValue);
    }
    let ind = classList.findIndex((i) => {
      return i.id == data.id;
    });
    console.log(ind, "handleChangedysgds");
    if (ind === -1) {
      setClassList([...classList, data]);
    } else {
      classList[ind] = data;
      setClassList([...classList]);
    }
  };

  const GotoGrade = () => {
    // if(sectionData?.length === 0){
    //   console.log(classList, "classListclassList2")

    let data = {
      sections: [],
    };

    classList.length > 0 &&
      classList.map((v, i) => {
        v.class.map((cls, idx) => {
          let vals = {};
          vals.grade_id = cls.grade;
          vals.institute_id = schoolId;
          vals.title = cls.title;

          data.sections.push(vals);
        });
      });

    console.log(data, "DDDDDDDDDDDDDd");
    // let data = [];
    // console.log(data,"dataBeforeitsadd");
    // classList.map((v, i) => {
    //     v.class.map(({ids, ...val}) => {
    //         data.push(val)

    //     })
    // })
    // if(classList?.length > 0){
    //   showEditsection(true)
    // }
    const datatoSave = Object.values(gradeData);
    console.log(data, "Datatosave");
    token_api
      .post(`base/v1/section/`, data) // as per backend dev requirements
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          alert("Section added Successfully");
          setOpneModel(false)
          // showEditsection(!showEditsection)
          // navigate(`/onboard/uploadteacher`)
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }else{
    //   console.log( sectionData,"Section data hit Patch");

    // }

    // navigate(`/onboard/uploadteacher`)
    // console.log(data, "data")
  };
  // useEffect(() =>{
  //   // setClassList([]);
  //   //  data=[]
  // }, [selectdata])
  console.log(classList, "classListclassList");
  // console.log(selectdata, "ahkuhdabdhedjadjaydged");
  return (
    <Dialog fullWidth={100} maxWidth={"lg"} open={open} onClose={onClose}>
      {/* <DialogTitle>Add Number Of section in each Grade</DialogTitle> */}
      <DialogContent>
        <div className={styles.mainBackground}>
          <div className={styles.subContainer}>
            {/* <div className={styles.heading}>
              <ArrowBackIcon
                style={{ fontSize: "35px", marginRight: "10px" }}
              />
             
            </div> */}
            <div className={styles.centerContainer}>
              <h2 className={styles.selectionHeading}>
                Add the number of sections in each Classes
               
              </h2>
              { sectionData?.length > 0 &&
                  sectionData?.map((v) =>{
                    <div>{v?.total_count}</div>
                  })
                 }
              <div className={styles.boards}>
                <div className={styles.gradeClass}>
                  {selectdata?.length === 0 ? (
                    <>Add Classes To Assign Sections</>
                  ) : (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={6}>
                            <h3
                              className={`${styles.whiteColor} ${styles.headingText}`}
                            >
                              Classes
                            </h3>
                          </Grid>
                          <Grid item xs={6}>
                            <h3
                              className={`${styles.whiteColor} ${styles.headingText}`}
                            >
                              Sections
                            </h3>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                        >
                          {selectdata?.length > 0 &&
                            selectdata?.map((v, i) => (
                              <div
                                key={i}
                                style={{ display: "flex", margin: "10px" }}
                              >
                                <Grid item xs={2} sm={4} md={6} lg={6}>
                                  <p
                                    className={styles.whiteColor}
                                    style={{ marginRight: "20px" }}
                                  >
                                    {v.title}
                                  </p>
                                </Grid>
                                <Grid item xs={2} sm={4} md={6} lg={6}>
                                  <TextField
                                    className={styles.textfields}
                                    style={{
                                      border: "1px solid white",
                                      outline: "none",
                                      color: "white",
                                    }}
                                    value={sectionData[i]?.total_count || ''}
                                    type="number"
                                    onChange={(e) => handleChange(e, v)}
                                  />
                                </Grid>
                              </div>
                            ))}
                        </Grid>
                      </Box>
                    </>
                  )}
                </div>
                <div className={styles.classes}>
                  {classList.length > 0 && (
                    <h3
                      className={`${styles.whiteColor} ${styles.headingText}`}
                    >
                      Classes
                    </h3>
                  )}
                  {
                    console.log(sectionData[0]?.total_count," insideComponent")
                  }
                  {classList.map((v, i) => (
                    <div className={styles.classContainer}>
                      {v.class.map((data, index) => (
                        <div className={styles.classData}>{data.title}</div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
            <Button variant="contained"
                className={styles.btns}
                onClick={() => GotoGrade()}
                // disabled={boolean}
              >
                Save
                </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CustomModel;

const classes = [
  {
    id: 1,
    title: "Class 6",
  },
  {
    id: 2,
    title: "Class 7",
  },
  {
    id: 3,
    title: "Class 8",
  },
  {
    id: 4,
    title: "Class 9",
  },
  {
    id: 5,
    title: "Class 10",
  },
];

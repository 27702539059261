import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./MindgrapModal.module.css";
import { Autocomplete, Drawer, TextField } from "@mui/material";
import { base_url, open_api } from "../../Utils/Network";
import dragIcon from "../../Assets/filedrag.png";

import axios from "axios";
import { Button } from "@mui/material";
import { useState } from "react";
import { token_api } from "../../Utils/Network";
// import Button from "@mui/material";
import { useEffect } from "react";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomSnackBar from "../CustomSnackBar/CustomSnackBar";
import SearchAndDropdown from "../SearchAndDropdown/SearchAndDropdown";
import { Title } from "@mui/icons-material";
import { Page } from "react-pdf";
// import DreamDict from "./DreamDict";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

export default function MindgrapModal({
  handleCloseModal = () => {},
  open,
  Id,
  getAllSchoolListing = () => {},
}) {
  console.log(Id, "hefuehwf");
  const fileInput = React.useRef(null);
  const [subjectList, setSubjectList] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [active, setActive] = useState(null);
  const [preData, setPreData] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [error, setError] = useState("");
  const [mindgraphDetails, setMindGraphDetails] = React.useState({
    student_id: "",

    name: null,
  });
  const { token_data } = React.useContext(UserCredsContext);
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    open: false,
    responseType: "",
  });
  const [accForm, setaccForm] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [required, setRequired] = useState();
  const [serviceBookingSeleted, setServiceBookingSelected] = useState({
    full_name: "",
    id: "",
    phone: "",
  });
  // useEffect(() =>{
  //     if(data){
  //         let newData =  data?.modifiedArray[0]
  //         let langObj = [newData]?.map((l) => {
  //             for (let i of subjectList) {
  //               if (l == i?.id) {
  //                 return i;
  //               }
  //             }
  //           });

  //         setMindGraphDetails({...mindgraphDetails,
  //             student_id: langObj,
  //         })
  //     }
  // },[data])
  // console.log(handleCloseModal, open, data?.modifiedArray[0].id, "ghafhewf");

  const getPreData = (id) => {
    // setPreData([])
    token_api
      .get(`counseling/mindreports/${id}/`)
      .then((response) => {
        setPreData(response.data?.data);
      })
      .catch((error) => console.log(error));
  };

  console.log(preData, "kjskjhs");
  useEffect(() => {
    if (Id) {
      getPreData(Id);
    }
  }, [Id]);

  const getDataList = () => {
    token_api
      .get(`counseling/dashboard/students`)
      .then((response) => {
        let newArray = response.data?.data?.map((sub, index) => {
          return {
            id: sub?.user_detail?.id,

            title: sub?.user_detail?.first_name,
          };
        });
        setDropList(newArray);

        console.log(newArray, "jdhnfjkhskjf");
      })
      .catch((error) => console.log(error));
  };

  console.log(dropList, "shdfhf");
  React.useEffect(() => {
    // if (Id) {
    getDataList();

    // }
  }, []);

  React.useEffect(() => {
    setMindGraphDetails({
      student_id: "",
      name: "",
      // subject_id: "",
    });
  }, [open]);
  const handleAutoComplete = (e, val) => {
    console.log("AUTOTOTO", val);

    setMindGraphDetails({
      ...mindgraphDetails,
      subject_id: val?.id,
      name: val,
    });
  };

  const handleClick = () => {
    fileInput.current.click();
    console.log(fileInput, "jhdfjhjgf");
  };
  const handleDrop = (e) => {
    e.preventDefault();
    console.log(e.target?.files, "gadgas");
    if (e?.target?.files) {
      setFileName(e.target.files[0]);

      // file = e.target.files[0];
    } else if (e?.dataTransfer?.files) {
      setFileName(e.dataTransfer.files[0]);

      // file = e.dataTransfer.files[0];
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      setFile(reader.result);
    };
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  console.log(fileName, "jsdjkhfkjsdhk");
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);

  // useEffect(() => {
  //     if (isListening) {

  //         handleListen();
  //     }
  // }, [isListening]);

  const handleListen = (id) => {
    console.log(id, "sjsdhfa");
    if (id) {
      console.log("something");
      if (isListening) {
        // debugger
        mic.start();
        mic.onend = () => {
          console.log("continue..");
          mic.start();
        };
      } else {
        mic.stop();
        mic.onend = () => {
          console.log("Stopped Mic on Click");
        };
      }
      mic.onstart = () => {
        console.log("Mics on");
      };

      mic.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        setNote(transcript);

        // Update the specific studentDream item based on the id
        setStudentDream((prevStudentDream) =>
          prevStudentDream.map((item) =>
            item.id === id ? { ...item, value: transcript } : item
          )
        );

        mic.onerror = (event) => {
          console.log(event.error);
        };
      };
    } else {
      console.log("something went wrong");
    }
  };
  // console.log(studentDream, "djkhfyiau8979979");
  const [card, setCard] = useState(false);

  const [studentDream, setStudentDream] = useState([
    {
      id: 1,
      name: "interest and aptitude result",
      value: "",
      // file:"",
    },
    {
      id: 2,
      name: "synchronization",
      value: "",
    },
    {
      id: 3,
      name: "visible improvements",
      value: "",
    },
    {
      id: 4,
      name: "conclusion",
      value: "",
    },
  ]);

  useEffect(() => {
    if (preData) {
      setStudentDream([
        {
          id: 1,
          name: "interest and aptitude result",
          value: preData.interest_and_aptitude_result
            ? preData.interest_and_aptitude_result
            : "",
        },
        {
          id: 2,
          name: "synchronization",
          value: preData.synchronization || "",
        },
        {
          id: 3,
          name: "visible improvements",
          value: preData.visible_improvements || "",
        },
        { id: 4, name: "conclusion", value: preData.conclusion || "" },
      ]);
      const name =
        (preData?.user?.first_name ? preData?.user?.first_name : "") +
        " " +
        (preData?.user?.last_name ? preData?.user?.last_name : "");
      // setMindGraphDetails({ name: name, id: preData?.user?.id });
      setServiceBookingSelected({
        first_name: preData?.user?.first_name ? preData?.user?.first_name : "",
        last_name: preData?.user?.last_name ? preData?.user?.last_name : "",
        id: preData?.user?.id ? preData?.user?.id : "",
        phone: preData?.user?.phone ? preData?.user?.phone : "",
      });
    }
  }, [preData]);

  const handleClearStates = () => {
    // debugger
    // if (!id) {
    setStudentDream([
      { id: 1, name: "interest and aptitude result", value: "" },
      { id: 2, name: "synchronization", value: "" },
      { id: 3, name: "visible improvements", value: "" },
      { id: 4, name: "conclusion", value: "" },
    ]);
    setServiceBookingSelected({
      first_name: "",
      last_name: "",
      id: "",
      phone: "",
    });
    setPreData([]);

    //   /
  };

  console.log(dropList, "kdfmsmdfk");

  console.log(studentDream[3]?.value, "90843098rj");

  const handleStudentDreamSubmit = (e) => {
    e.preventDefault();
    setCard(true);
    let data = {};

    console.log("Hitbeforeapi", data);
  };

  const handleChange = (e, id) => {
    const updatedStudentDream = studentDream.map((item) =>
      item.id === id ? { ...item, value: e.target.value } : item
    );
    setStudentDream(updatedStudentDream);
  };

  console.log(studentDream, "ehfhsgf");

  const handleMicClick = (id) => {
    setActive(id);
    handleListen(id);
    setIsListening(!isListening);
  };
  console.log(active, "dhfkjhsf");

  console.log(mindgraphDetails.student_id, preData?.user?.id, "asghjdgajsg");

  const postData = (id) => {
    // if(!id) return setError("Select Student")

    console.log(id, "postData");

    var formData = new FormData();

    // if (studentDream[0].value || studentDream[1].value || studentDream[2].value || studentDream[3].value) {
    //     formData.append("user_id", id);
    //     formData.append("file", fileName);
    //     formData.append("interest_and_aptitude_result", studentDream[0].value);
    //     formData.append("synchronization", studentDream[1].value);
    //     formData.append("visible_improvements", studentDream[2].value);
    //     formData.append("conclusion", studentDream[3].value);
    // }
    // Conditionally append each field if there is data present
    if (studentDream[0]?.value) {
      formData.append("interest_and_aptitude_result", studentDream[0].value);
    }
    if (studentDream[1]?.value) {
      formData.append("synchronization", studentDream[1].value);
    }
    if (studentDream[2]?.value) {
      formData.append("visible_improvements", studentDream[2].value);
    }
    if (studentDream[3]?.value) {
      formData.append("conclusion", studentDream[3].value);
    }

    // Append user ID and file if they are present
    formData.append("user_id", id);
    if (fileName) {
      formData.append("file", fileName);
    }
    const url = Id
      ? `${base_url}counseling/mindgraph/report2/${Id}/update_mindgrapher_report/`
      : `${base_url}counseling/mindgraph/report2/mind_grapher/`;

    const newMethod = Id ? "patch" : "post";

    axios({
      method: newMethod,

      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token_data.access}`,
      },
    })
      .then((response) => {
        console.log("MIndResponse", response);
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          // alert("File was uploaded successfully.");
          // handleClearStates();
          // setState(false);
        //   debugger
          
          setSnackbarData({
            message: "Mindgraph Report details updated successfully",
            open: true,
            responseType: "success",
          });
         
          setFile(null);
          setFileName("");
          setStudentDream([
            { id: 1, name: "interest and aptitude result", value: "" },
            { id: 2, name: "synchronization", value: "" },
            { id: 3, name: "visible improvements", value: "" },
            { id: 4, name: "conclusion", value: "" },
          ]);
          
        }
        handleCloseModal();
          getAllSchoolListing({q: "", page:1, });
      })

      .catch((err) => {
        // alert("Error in uploading file, please try again.");
        setSnackbarData({
          message: "Error while updating mindgraph details",
          open: true,
          responseType: "error",
        });
      });

    const timeOut = setTimeout(handleOpen, 3000);
    function handleOpen() {
      setSnackbarData({
        message: "",
        open: false,
        responseType: "success",
      });
    }
  };

  const handleAutocompleteChangeforServiceBooking = (selectedValues) => {
    setServiceBookingSelected(selectedValues);
  };

  console.log(mindgraphDetails, serviceBookingSeleted, "dhjfhsdkjhfs");
  console.log(fileName, "hdsgkjhdskg");
  return (
    <>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            <Drawer
              anchor={anchor}
              open={open}
              onClose={() => {
                handleClearStates();

                handleCloseModal(false);
              }}
            >
              <Typography sx={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    marginTop: "50px",
                  }}
                  onClick={() => {
                    handleClearStates();
                    handleCloseModal();
                  }}
                >
                  <CloseIcon />
                </div>
                <div className={styles.con}>
                  <SearchAndDropdown
                    error={error}
                    value={serviceBookingSeleted}
                    onChange={handleAutocompleteChangeforServiceBooking}
                  />

                  {error && <p>{error}</p>}
                  <div className={styles.top}>
                    <div
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      className={styles.dropBoxStyles}
                    >
                      <div
                        className={styles.flexStyleDropBox}
                        onClick={handleClick}
                      >
                        <p
                          style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img src={dragIcon} alt="dradanddrop" />
                          <p>Drag & Drop the Files or click here</p>
                        </p>
                        <input
                          ref={fileInput}
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleDrop}
                          accept=".edf"
                        ></input>
                      </div>
                    </div>
                    {fileName && !file && (
                      <p
                        style={{
                          color: "green",
                          margin: "0 auto",
                          marginBottom: "10px",
                          width: "90%",
                        }}
                      >
                        File {fileName?.name} is uploaded.
                      </p>
                    )}
                  </div>
                </div>
              </Typography>
              <Typography>
                <div className={styles.cardContainer}>
                  {studentDream?.map((items) => {
                    return (
                      <div>
                        <div className={styles.editStyle}>
                          <span className={styles.font20}>{items.name}</span>
                          <div
                            className={styles.micCon}
                            onClick={() => handleMicClick(items.id)}
                          >
                            {active === items.id && !isListening ? (
                              <MicIcon sx={{ color: "#02f733" }} />
                            ) : (
                              <MicOffIcon sx={{ color: "#f71302" }} />
                            )}
                          </div>

                          <div style={{ display: "flex" }}></div>
                        </div>
                        {card == false ? (
                          <>
                            <div>
                              <form onSubmit={handleStudentDreamSubmit}>
                                <TextField
                                  fullWidth
                                  onChange={(e) => handleChange(e, items.id)}
                                  // onClick={() => { setActive(items.id) }}

                                  name={items?.name}
                                  value={items?.value}
                                  multiline
                                  required
                                  rows={4}
                                  // disabled={!active}
                                />
                              </form>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={styles.showCard}>
                              {studentDream && studentDream}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Typography>
              <Button
                style={{
                  position: "relative",
                  left: "60%",
                  margin: "10px",
                  padding: "10px 40px",
                  width: "150px",
                }}
                type="submit"
                size="small"
                variant="contained"
                onClick={() => {
                  postData(serviceBookingSeleted?.id);
                  handleClearStates();
                  setFileName("");
                }}
              >
                Save
              </Button>
            </Drawer>
          </React.Fragment>
        ))}
      </div>

      <CustomSnackBar
        message={snackbarData.message}
        openSnack={snackbarData.open}
        severity={snackbarData.responseType}
      />
    </>
  );
}

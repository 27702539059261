import React from "react";
import styles from "./index.module.css";
import InputField from "../../Components/Input/InputFields";
import { Button, TextField } from "@mui/material";
import CustomSelect from "../../Components/CustomSelect/CustomSelect";
import { useState } from "react";
import { useEffect } from "react";
import { token_api } from "../../Utils/Network";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { helperDateReverese } from "../../Utils/helper";
import CustomMultiSelectChips from "../../Components/CustomMultiSelectChips/CustomMultiSelectChips";

const AddSchool = ({
  setOpenDrawer,
  editState,
  editObject,
  setEditState,
  setEditObject,
}) => {
  const [schoolData, setSchoolData] = useState({
    name: "",
    email_id: "",
    primary_contact: "",
    secondary_contact: "",
    school_medium: "",
    location: "",
    website: "",
    since: "",
    language_id: [],
    board_id: [],
    grade_id: [],
    since: null,
    user: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const [languageList, setLanguageList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  console.log(editObject, "editObjectwheneditopne");
  const validate = (value) => {
    console.log("valalal", value);
    const { name, email_id, primary_contact, language_id, board_id, grade_id } =
      schoolData;
    const errors = {
      error: false,
    };

    if (!name) {
      errors.name = "Enter school";
      errors.error = true;
    }

    if (!primary_contact) {
      errors.primary = "Enter primary contact";
      errors.error = true;
    }

    if (!email_id) {
      errors.email = "Enter email";
      errors.error = true;
    }

    if (board_id.length == 0) {
      errors.board = "Select board";
      errors.error = true;
    }

    if (grade_id.length == 0) {
      errors.grade = "Select grade";
      errors.error = true;
    }

    if (language_id.length == 0) {
      errors.language = "Select medium";
      errors.error = true;
    }
    setFormErrors(errors);
    return errors;
  };

  const handleAddSchool = () => {
    console.log("EDDIDIDI", editState);

    let validateResults = validate(schoolData);

    const { error } = validateResults;
    if (error) return;

    if (editState) {
      let langIds = schoolData?.language_id?.map((l, i) => {
        return l?.id;
      });
      let boardIds = schoolData?.board_id?.map((l, i) => {
        return l?.id;
      });

      let gradeIds = schoolData?.grade_id?.map((l, i) => {
        return l?.id;
      });
      let data = {
        user_id: schoolData?.user,
        institute_profile: {
          language_id: langIds,
          board_id: boardIds,
          grade_id: gradeIds,
          name: schoolData?.name,
          email_id: schoolData?.email_id,
          primary_contact: schoolData?.primary_contact,
          secondary_contact: schoolData?.secondary_contact,
          school_medium: schoolData?.school_medium,
          location: schoolData?.location,
          website: schoolData?.website,
          since: helperDateReverese(schoolData?.since),
        },
      };
      token_api
        .post(`profile/v1/institute/update_institute_profile/`, data)
        .then((response) => {
          console.log("UUUUUUU", response);
          setOpenDrawer(false);
        })
        .catch((err) => {
          console.log(err);
          alert("Error in updating school.");
        });
    } else {
      let langIds = schoolData?.language_id?.map((l, i) => {
        return l?.id;
      });
      let boardIds = schoolData?.board_id?.map((l, i) => {
        return l?.id;
      });

      let gradeIds = schoolData?.grade_id?.map((l, i) => {
        return l?.id;
      });

      let data = {
        institute_profile: {
          // language_id: langIds,
          board_id: boardIds,
          grade_id: gradeIds,
          name: schoolData?.name,
          email_id: schoolData?.email_id,
          primary_contact: schoolData?.primary_contact,
          secondary_contact: schoolData?.secondary_contact,
          school_medium: schoolData?.school_medium,
          location: schoolData?.location,
          website: schoolData?.website,
          since: helperDateReverese(schoolData?.since),
        },
      };
      token_api
        .post(`profile/v1/institute/create_institute/`, data)
        .then((response) => {
          console.log("CrateSchoolResponse", response);
          setOpenDrawer(false);
        })
        .catch((err) => {
          console.log(err);
          alert("Error in creating school");
        });
    }
  };

  const handleChangeChips = (e, chipType) => {
    // const { value } = e.target;
    console.log("jdfjkdjksdkj", e);

    if (chipType === "lang") {
      setSchoolData({
        ...schoolData,
        language_id: typeof e === "string" ? e.split(",") : e,
      });
    }
    if (chipType === "board") {
      setSchoolData({
        ...schoolData,
        board_id: typeof e === "string" ? e.split(",") : e,
      });
    }
    if (chipType === "grade") {
      setSchoolData({
        ...schoolData,
        grade_id: typeof e === "string" ? e.split(",") : e,
      });
    }
  };

  const handleDeleteChips = (id, chipType) => {
    console.log("poli", id, chipType);
    if (chipType == "grade" ) {
    
      let storeVal = schoolData?.grade_id.filter((chip) => chip.id !== id);
    
      setSchoolData({
        ...schoolData,
        grade_id: storeVal,
      });
    }
    if (chipType === "lang") {
      let storeVal = schoolData?.language_id.filter((chip) => chip.id !== id);
      setSchoolData({
        ...schoolData,
        language_id: storeVal,
      });
    }
    if (chipType === "board") {
      let storeVal = schoolData?.board_id.filter((chip) => chip.id !== id);
      setSchoolData({
        ...schoolData,
        board_id: storeVal,
      });
    }
   
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolData({
      ...schoolData,
      [name]: value,
    });
  };

  console.log("SchoolData", schoolData);

  const getBoardList = () => {
    token_api
      .get(`base/board/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setBoardList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLanguageList = () => {
    token_api
      .get(`base/language/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setLanguageList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("asdasdsdsdddgdfg", languageList);
  const getGradeList = () => {
    token_api
      .get(`base/grade/`)
      .then((response) => {
        let modifiedArray = response.data.data?.map((item) => {
          return {
            id: item?.id,
            title: item?.title,
          };
        });

        setGradeList([...modifiedArray]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // all listing calls
    getBoardList();
    getLanguageList();
    getGradeList();
  }, []);

  useEffect(() => {
    if (editState) {
      setSchoolData({ ...editObject });
    }
  }, []);

  console.log("edieiei", editState);

  return (
    <>
      <div className={`${styles.addContainer}`}>
        {
          editState ? <div className={`${styles.titleStyles}`}> School Details</div> : <div className={`${styles.titleStyles}`}>Add School</div>
        }
        {/* <div className={`${styles.titleStyles}`}>Add School</div> */}
        <div className={`${styles.formBox}`}>
          <InputField
            label={"School Name"}
            onChange={handleChange}
            name="name"
            value={schoolData?.name}
            error={formErrors?.name}
            helperText={formErrors?.name}
          />
          <InputField
            label={"School Email"}
            onChange={handleChange}
            name="email_id"
            value={schoolData?.email_id}
            error={formErrors?.email}
            helperText={formErrors?.email}
          />
          <InputField
            label={"Primary Contact"}
            onChange={handleChange}
            name="primary_contact"
            value={schoolData?.primary_contact}
            error={formErrors?.primary_contact}
            helperText={formErrors?.primary_contact}
          />
          <InputField
            label={"Secondary Contact"}
            onChange={handleChange}
            name="secondary_contact"
            value={schoolData?.secondary_contact}
            error={formErrors?.secondary_contact}
            helperText={formErrors?.secondary_contact}
          />
          <InputField
            label={"Location"}
            onChange={handleChange}
            name="location"
            value={schoolData?.location}
            error={formErrors?.location}
            helperText={formErrors?.location}
          />
          <InputField
            label={"Website"}
            onChange={handleChange}
            name="website"
            value={schoolData?.website}
            // helperText={formErrors}
          />

          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Since"
                value={schoolData?.since}
                onChange={(newValue) => {
                  setSchoolData({
                    ...schoolData,
                    since: newValue,
                  });
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </div>

          <div>
            <CustomSelect
              listarray={languageList}
              label={"Medium"}
              name="school_medium"
              onChange={handleChange}
              value={schoolData?.school_medium}
              size={"small"}
              error={formErrors?.language}
            />
          </div>
          <div>
            <CustomMultiSelectChips
              listArray={languageList}
              label={"Language"}
              name="language_id"
              onChange={(e) => handleChangeChips(e, "lang")}
              handleDelete={(id) => handleDeleteChips(id, "lang")}
              value={schoolData?.language_id}
              size={"small"}
              error={formErrors?.language}
            />
          </div>
          <div>
            <CustomMultiSelectChips
              listArray={boardList}
              label={"Board"}
              name="board_id"
              onChange={(e) => handleChangeChips(e, "board")}
              handleDelete={(id) => handleDeleteChips(id, "board")}
              value={schoolData?.board_id}
              size={"small"}
              error={formErrors?.board}
            />
          </div>
          <div>
            <CustomMultiSelectChips
              listArray={gradeList}
              label={"Grade"}
              name="grade_id"
              onChange={(e) => handleChangeChips(e, "grade")}
              handleDelete={(id) => handleDeleteChips(id, "grade")}
              value={schoolData?.grade_id}
              size={"small"}
              error={formErrors?.grade}
            />
          </div>
        </div>
        <div className={`${styles.btnContainer}`}>
          <div>
            {
              editState ? <Button variant="contained" onClick={handleAddSchool}>
              Save
            </Button> : <Button variant="contained" onClick={handleAddSchool}>
              Add
            </Button>
            }
            {/* <Button variant="contained" onClick={handleAddSchool}>
              Add
            </Button> */}
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setOpenDrawer((prev) => !prev);
                setEditState(false);
                setEditObject({});
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSchool;

import { useEffect, useState } from "react";
import styles from './MindgrapherReport.module.css'
import CustomTable from "../../Components/CustomTable/CustomTable";
import { Button, Pagination, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { token_api } from "../../Utils/Network";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import editIcon from "../../Assets/EditIcon.svg"
import DownloadIcon from '@mui/icons-material/Download';
import MindgrapModal from "../MindgrapModal/MindgrapModal";
import { helperDateReverese } from "../../Utils/helper";
import DeleteModal from "../DeleteModal/DeleteModal";
function MindgrapherReport() {
    const [schoolList, setSchoolList] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [filterData, setFilterData] = useState({
        q: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [pageState, setPageState] = useState(1);
    const [opneModel, setOpneModel] = useState(false);
    const { page, previous_page, max_pages, total_count, count } = paginationData;
    const [editId, setEditId] = useState()
    const [openPop, setOpenPop] = useState(false)
    const [data, setData] = useState([])
    useEffect(() => {
        const timeout = setTimeout(() => {
            let obj = filterData;
            obj.page = pageState;

            console.log(obj, "wqklej987y6");
            
            getAllSchoolListing(obj);
        }, 600);
        return () => clearTimeout(timeout);
    }, [pageState, filterData?.q]);

    const handleDownload = (id) => {



        token_api
            .get(`counseling/mindgraph/report2/mind_grapher_report?report_id=${id}`)
            .then((response) => {
                console.log("ChekResponse", response);
                const { data } = response?.data;
                console.log(data, "hfghjgfh");

                if (data.report_pdf) {
                    window.open(data?.report_pdf);
                } else {
                    alert("Error! Report does not exist");
                }



            })





        // counseling/mindgraph/report2/mind_grapher_report?report_id=5'
        // if (data) {
        //     window.open(data?.report_pdf);
        // } else {
        //     alert("Error! Report does not exist");
        // }

    }
    const handleEdit = (id) => {
        setOpneModel(true)
        setEditId(id)

    }
    const handleDelete = (id, name) => {
        setOpenPop(true)
        setData({
            id: id,
            name: name
        })
    }
    const closePopup = () => {
        setOpenPop(false)
    }
    console.log(schoolList, "hdfkjhskhf");
    const getAllSchoolListing = ({ paramsObj = { ...filterData } }) => {
        setIsLoading(true);
        setSchoolList([])
        token_api
            .get(`counseling/mindreports/`, { params: { ...paramsObj } })
            .then((response) => {
                console.log("ChekResponse", response);
                const { data } = response?.data;

                let modifiedArray = data?.map?.((school) => {
                    const { id, user, created, modified, status_str, created_by, modified_by, report_pdf } = school;
                    const name = user?.first_name + " " + user?.last_name

                    return {
                        id: user.id || "N.A",
                        // school: name || "N.A",
                        // location: user.location || "N.A",
                        mobile: user.phone || "N.A",
                        student: name || "N.A",
                        email: user.email || "N.A",
                        updatedon: helperDateReverese(modified) || "N.A",
                        createdon: helperDateReverese(created) || "N.A",
                        status: status_str
                            || "N.A",
                        createdby: created_by || "N.A",
                        updatedby: modified_by || "N.A",

                        report: (
                            <div>
                                <span style={{ cursor: "pointer", marginLeft: "20px" }}  ><DownloadIcon onClick={() => handleDownload(id)} /></span>
                            </div>
                        ),
                        edit: (
                            <div onClick={() => handleEdit(id)} >
                                <span style={{ cursor: "pointer", marginLeft: "20px" }} ><img src={editIcon} /></span>
                            </div>
                        ),
                        delete: (
                            <div onClick={() => handleDelete(id, name)} >
                                <span style={{ cursor: "pointer", marginLeft: "20px" }} ><DeleteForeverIcon /></span>
                            </div>
                        ),



                        // section:(
                        //   <div style={{cursor:"pointer"}} onClick={() => handleAddSections(id,grade, section)}>
                        //     {showEditsection ? "Edit sections" : "Add sections"}
                        //   </div>
                        // ),
                        // course:(
                        //   <div style={{cursor:"pointer"}} onClick={() => handleAddCourse(id, section)}>
                        //     Assign Courses
                        //   </div> 
                        // ),

                    };

                });

                setSchoolList([...modifiedArray]);
                setPaginationData(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleOpenModal = () => {
        setOpneModel(true)
    }
    const handleCloseModal = () => {
        setOpneModel(false)
    }



    return (
        <>
            <div className={`${styles.conatiner}`}>

                <div className={`${styles.filterBox}`}>
                    <div>
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            size="small"
                            InputProps={{
                                startAdornment: <SearchIcon />,
                            }}
                            value={filterData?.name}
                            onChange={(v) => {
                                setFilterData({
                                    ...filterData,
                                    q: v.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className={`${styles.btnFlex}`}>
                        <div>
                            <Button variant="contained"
                                onClick={handleOpenModal}

                            >
                                Add new
                            </Button>
                        </div>

                    </div>
                </div>
                <div className={`${styles.tableBox}`}>
                    <CustomTable
                        columns={columns}
                        data={schoolList}
                        isLoading={isLoading}
                    />
                </div>
                <div style={{ marginTop: "12px" }}>
                    <Pagination
                        count={max_pages}
                        variant="outlined"
                        shape="rounded"
                        onChange={(e, value) => setPageState(value)}
                    />


                </div>
                <MindgrapModal handleCloseModal={handleCloseModal} getAllSchoolListing={getAllSchoolListing} open={opneModel} Id={editId} />
                <DeleteModal closePopup={closePopup} open={openPop} data={data} getAllSchoolListing={getAllSchoolListing}/>
            </div>
        </>
    )
}

export default MindgrapherReport
const columns = [
    { id: "id", label: "ID" },
    { id: "student", label: "Student Name" },
    { id: "mobile", label: "Mobile" },
    { id: "email", label: "Email" },

    { id: "report", label: "Report" },
    { id: "status", label: "Status" },
    { id: "createdby", label: "Created By" },
    { id: "updatedby", label: "Updated By" },
    { id: "createdon", label: "Created On" },
    { id: "updatedon", label: "Updated On" },
    { id: "edit", label: "Edit" },
    { id: "delete", label: "Delete" },



    // Add more columns as needed
];
import axios from "axios";
// import { LOGIN_API } from "./Constants";
export var base_url = "";
export const access_token = localStorage.getItem("brainhapAdmin_user_cred_context");
export const user_access_token = localStorage.getItem("token")

if (
  window.location.href.indexOf("demo") > -1 ||
  window.location.href.indexOf("localhost") > -1
) {

  console.log("llllllllllllll" , window.location.href );
  // base_url = "https://demo-api.brainhap.com";
  base_url = "https://prod-api.brainhap.com/";
} else {
  base_url = "https://prod-api.brainhap.com/";
}
var creds_data = "";
var _access_token = "";
if (localStorage.hasOwnProperty("brainhapAdmin_user_cred_context")) {
  creds_data = JSON.parse(localStorage.getItem("brainhapAdmin_user_cred_context"));
  if (creds_data.hasOwnProperty("token")) {
    _access_token = creds_data.token.access;
  } else {
    _access_token = "";
  }
}

const headersObject = {
  "Content-Type": "application/json",
};

export var open_api = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: headersObject,

  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export var token_api = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: headersObject,

  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

token_api.interceptors.request.use((req) => {
  var _access_token = "";
  if (localStorage.hasOwnProperty("brainhapAdmin_user_cred_context")) {
    creds_data = JSON.parse(localStorage.getItem("brainhapAdmin_user_cred_context"));
    if (creds_data.hasOwnProperty("token")) {
      _access_token = creds_data.token.access;
    } else {
      _access_token = "";
    }
  }
  req.headers.Authorization = `Bearer ${_access_token}`;
  return req;
});
import { Button } from "@mui/material";
import React, { useContext } from "react";
import InputField from "../Components/Input/InputFields";
import { useReducer } from "react";
import { loginReducer } from "./loginReducer";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../ContextApi/UserCredsContext/UserCredsContext";
import { open_api } from "../Utils/Network";
import { useState } from "react";

const FormFields = () => {
  let navigate = useNavigate();
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const [isloging, setIsLoging] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { setUserState, setUser, setToken } = useContext(UserCredsContext);

  console.log("state", state);
  const {
    username,
    password,
    otp,
    isLoading,
    error,
    isLoggedIn,
    showOtp,
    email,
  } = state;
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "field",
      fieldName: name,
      payload: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "login" });
    const data = {
      username: username,
      password: password,
    };
    // api call

    setIsLoging(true);

    open_api
      .post(`/auth/user/counselor_login/`, data)
      .then((response) => {
        console.log("loginnnn", response.data.data);
        if (response.data.data && response.status == 200) {
          // setIsLoging(false)
          const { token, user } = response.data.data;
          setUser(user);
          setToken(token);
          const tokenString = JSON.stringify({ token });

          dispatch({ type: "success" });
          localStorage.setItem("brainhapAdmin_user_cred_context", tokenString);
          localStorage.setItem("token", token.access);
          localStorage.setItem("user", response.data.data.user.id);
          // console.log("ApiCall", data);
          navigate(`/dashboard/students`);
        } else {
          dispatch({ type: "error" });
        }
      })
      .catch((err) => {
        setIsLoging(false);
        setFormErrors({
          ...formErrors,
          name: "Username or password incorrect.",
          password: "Username or password incorrect.",
        });
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <form onSubmit={onSubmit} className={styles.authFormContainer}>
          <InputField
            style={{
              background: "white",
              width: "100%",
              marginBottom: "15px",
            }}
            label="Username"
            // value={username}
            placeholder="username"
            name="username"
            onChange={handleChange}
            size="normal"
            type="text"
            error={formErrors?.name}
            helperText={formErrors?.name}
          />
          <InputField
            style={{
              background: "white",
              width: "100%",
              marginBottom: "15px",
            }}
            label="Password"
            //   value={password}
            placeholder="password"
            name="password"
            onChange={handleChange}
            size="normal"
            type="password"
            error={formErrors?.password}
            helperText={formErrors?.password}
          />

          <Button
            disabled={isloging}
            type="submit"
            size="large"
            style={{ width: "100%" }}
            variant="contained"
          >
            {isloging ? "Loging in..." : "Login"}
          </Button>
        </form>
      </div>
    </>
  );
};

export default FormFields;

const initialState = {
  username: "",
  password: "",
  isLoading: false,
  error: "",
  isLoggedIn: false,
  showOtp: false,
  otp: "",
  email: "",
};

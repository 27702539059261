import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

const CustomSelect = ({
  value,
  onChange,
  name,
  label,
  size,
  style,
  listarray,
  error,
  ...props
}) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <FormControl fullWidth size={size} error={error}>
        <InputLabel size={size} id="demo-simple-select-label">
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          size={size}
          label={label}
          name={name}
          style={style}
          onChange={onChange}
        >
          {listarray.map((item, i) => {
            return (
              <MenuItem key={i} value={item?.id}>
                {item?.title || item?.name}
              </MenuItem>
            );
          })}

          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomSelect;

import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./pp.module.css";
import { token_api } from "../../Utils/Network";
import CustomTable from "../../Components/CustomTable/CustomTable";
import editIcon from "../../Assets/EditIcon.svg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ProductListingDialog from "../../Components/ProductListingDialog/ProductListingDialog";
import CustomSnackBar from "../../Components/CustomSnackBar/CustomSnackBar";
import { useNavigate } from "react-router-dom";

function ProductPurchase() {
  const navigate = useNavigate();
  const [studentList, setStudentList] = useState([]);
  const [filterData, setFilterData] = useState({
    q: "",
  });
  const [paginationData, setPaginationData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageState, setPageState] = useState(1);
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [openProductPopup, setOpneProductPopup] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    open: false,
    responseType: "",
  });
  useEffect(() => {
    const timeout = setTimeout(() => {
      let obj = filterData;
      obj.page = pageState;

      console.log(obj, "wqklej987y6");

      getstudentdata(obj);
    }, 600);
    return () => clearTimeout(timeout);
  }, [pageState, filterData?.q]);
  const getstudentdata = ({ paramsObj = { ...filterData } }) => {
    setIsLoading(true);
    setStudentList([]);
    token_api
      .get(`counseling/dashboard/students`, { params: { ...paramsObj } })
      //   .get(`counseling/mindreports/`, { params: { ...paramsObj } })
      .then((res) => {
        console.log(res.data.dat);
        const { data } = res?.data;

        let modifiedArray = data?.map?.((school) => {
          const {
            id,
            user,
            full_name,
            modified,
            phone,
            status_str,
            user_detail,
            // created_by,
            // modified_by,
            // report_pdf,
          } = school;
          //   const name = user?.first_name + " " + user?.last_name;

          return {
            id: user_detail?.id || "N.A",
            // school: name || "N.A",
            // location: user.location || "N.A",
            mobile: user_detail.phone || "N.A",
            student: (
              <div onClick={() => handleView(school)}>
                <span style={{ cursor: "pointer", marginLeft: "20px" }}>
                  {user_detail.full_name || "N.A"}
                </span>
              </div>
            ),
            // email: user.email || "N.A",
            // updatedon: helperDateReverese(modified) || "N.A",
            // createdon: helperDateReverese(created) || "N.A",
            // status: status_str || "N.A",

            // report: (
            //     <div>
            //         <span style={{ cursor: "pointer", marginLeft: "20px" }}  ><DownloadIcon onClick={() => handleDownload(id)} /></span>
            //     </div>
            // ),
            edit: (
              <div onClick={() => handleEdit(school)}>
                <span style={{ cursor: "pointer", marginLeft: "20px" }}>
                  {/* <img src={editIcon} /> */}
                  <ShoppingCartIcon />
                </span>
              </div>
            ),

            // view: (
            //   <div onClick={() => handleEdit(school)}>
            //     <span style={{ cursor: "pointer", marginLeft: "20px" }}>
            //       {/* <img src={editIcon} /> */}
            //       <ShoppingCartIcon />
            //     </span>
            //   </div>
            // ),
            // delete: (
            //     <div onClick={() => handleDelete(id, name)} >
            //         <span style={{ cursor: "pointer", marginLeft: "20px" }} ><DeleteForeverIcon /></span>
            //     </div>
            // ),

            // section:(
            //   <div style={{cursor:"pointer"}} onClick={() => handleAddSections(id,grade, section)}>
            //     {showEditsection ? "Edit sections" : "Add sections"}
            //   </div>
            // ),
            // course:(
            //   <div style={{cursor:"pointer"}} onClick={() => handleAddCourse(id, section)}>
            //     Assign Courses
            //   </div>
            // ),
          };
        });

        setStudentList([...modifiedArray]);
        setPaginationData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const handleView = (data) => {
    navigate(`/dashboard/product/${data?.user_detail?.id}` , {state:data})
  }

  const handleEdit = (data) => {
    console.log(data, "handleEdit");
    setDialogData(data);
    setOpneProductPopup(true);
  };

  const handleClose = () => {
    setOpneProductPopup(false);
    setDialogData({});
  };
  return (
    <>
      <div className={`${styles.conatiner}`}>
        <div className={`${styles.filterBox}`}>
          <div>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              value={filterData?.name}
              onChange={(v) => {
                setFilterData({
                  ...filterData,
                  q: v.target.value,
                });
              }}
            />
          </div>
          <div className={`${styles.btnFlex}`}>
            {/* <div>
              <Button
                variant="contained"
                // onClick={handleOpenModal}
              >
                Add new
              </Button>
            </div> */}
          </div>
        </div>

        <div className={`${styles.tableBox}`}>
          <CustomTable
            columns={columns}
            data={studentList}
            isLoading={isLoading}
          />
        </div>

        <ProductListingDialog
          open={openProductPopup}
          handleClose={handleClose}
          data={dialogData}
        />

        <CustomSnackBar
          message={snackbarData.message}
          openSnack={snackbarData.open}
          severity={snackbarData.responseType}
          setSnackbarData={setSnackbarData}
        />
      </div>
    </>
  );
}

export default ProductPurchase;

const columns = [
  { id: "id", label: "ID" },
  { id: "student", label: "Student Name" },
  { id: "mobile", label: "Mobile" },
  //   { id: "email", label: "Email" },

  //   { id: "report", label: "Report" },
  //   { id: "status", label: "Status" },
  //   { id: "createdby", label: "Created By" },
  //   { id: "updatedby", label: "Updated By" },
  //   { id: "createdon", label: "Created On" },
  //   { id: "updatedon", label: "Updated On" },
  { id: "edit", label: " Purchase Product" },
  // { id : "view" , label: "View History"}
  //   { id: "delete", label: "Delete" },
];

import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const CustomMultiSelectChips = ({
  label,
  handleDelete,
  onChange,
  value,
  listArray,
  error,
}) => {

  console.log("sddadasdasddfwefwe" , listArray);
  return (
    <>
      <div>
        <FormControl sx={{ width: "100%" }} size="small" error={error}>
          <InputLabel size="small" id="demo-simple-select-label">
            {label}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select-label"
            multiple
            label={label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            // name="subject_id"
            size="small"
            input={
              <OutlinedInput
                size="small"
                id="select-multiple-chip"
                label={label}
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  return (
                    <Chip
                      label={value?.title}
                      key={value?.id}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      // onClick={handleDeleteChip}
                      onDelete={(e) => handleDelete(value?.id)}
                    />
                  );
                })}
              </Box>
            )}
            // MenuProps={MenuProps}
          >
            {listArray?.map((sub, key) => (
              <MenuItem key={key} value={sub}>
                {sub.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      </div>
    </>
  );
};

export default CustomMultiSelectChips;

import React from "react";
import { Drawer } from "@mui/material";

const DrawerComponent = ({
  children,
  width = "40%",
  anchor,
  open,
  onClose = () => {},
}) => {
  // console.log(children,"whatisthischilders");
  return (
    <div>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: width },
        }}
      >
        {children}
      </Drawer>
    </div>
  );
};

export default DrawerComponent;
